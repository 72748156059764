import { compose } from "@reduxjs/toolkit";
import { Spin } from "antd";
import React from "react";
import withRouter from "../../network/with-router";
import { signOut } from "../../redux/auth-slice";
import { clearCookie } from "../../redux/cookie-slice";
import { clearProfile } from "../../redux/profile-slice";
import CustomPath from "../../routes/custom-path";

class Logout extends React.Component {

    componentDidMount() {
        this.props.dispatch(signOut())
        this.props.dispatch(clearCookie())
        this.props.dispatch(clearProfile())
        this.props.navigate(CustomPath.login)
    }

    render() {
        return (
            <>
                <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: 25, fontWeight: '500' }}>Logging Out</span><br /><br /><br />
                        <Spin size='large' />
                    </div>

                </div>
            </>
        )
    }
}

export default compose(withRouter)(Logout)