import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    InboxOutlined,
    LoadingOutlined,
    MenuOutlined,
    PrinterOutlined,
    QuestionOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Card, Checkbox, Col, ConfigProvider, Divider, Dropdown, Form, Modal, Radio, Row, Segmented, Skeleton, Space, Spin, Steps, Table, Upload, message } from "antd";
import dayjs from 'dayjs';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import animationData from '../../animate-lottie/animation_lnfk8gz0.json';
import FailAnimation from '../../animate-lottie/lottie-404.json';
import ShippingAnimation from '../../animate-lottie/lottie-shipping.json';
import SuccessAnimation from '../../animate-lottie/lottie-success.json';
import NoParcelCreate from '../../animate-lottie/package-cancel.json';
import { NJVButton, NJVDatePicker, NJVInput, NJVSelect } from '../../components/core-component';
import { SvgBrushOutlined, SvgMailBoxFilled, SvgOrderDollyFilled, SvgPlusFilled, SvgTruckFilled } from '../../components/custom-svgIcon';
import { FormLabel, TitleLevel3 } from '../../components/general-component';
import Theme, { Colors } from '../../components/theme';
import { ParcelAmountValidator, PhoneNumberValidator } from '../../core/validator';
import '../../css/order-create.css';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, DownloadOrderFormatExcel } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import { clearOrder, updateAdvanceOrderList, updateOrderSession } from '../../redux/order-form-slice';
import CustomPath from '../../routes/custom-path';
import InsuranceForm from './insurance-form';
const { Dragger } = Upload;

const HOME_DELIVERY = 1
const BUS_GATE = 2
const NINJA_POINT = 3
const POST_OFFICE = 4

const COLLECT_FROM_CUSTOMER = 'Collect from customer'
const PAY_BY_SHIPPER = 'Pay by shipper'
const DELIVERY_FEES = 2000

const stepLabelStyle = {
    fontWeight: '500',
    fontSize: 14
}

const segmentedStyle = {
    fontWeight: '600',
    fontSize: 14,
}

const orderTypeCardStyle = {
    backgroundColor: Theme.colors.light_gray,
    height: 65,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 11,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
}

const orderTypeCardStyleDisable = {
    backgroundColor: Theme.colors.light_gray,
    height: 65,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 11,
    display: 'flex',
    alignItems: 'center',
    cursor: 'not-allowed',
    opacity: 0.6
}

const orderTypeCardStyleSelected = {
    height: 65,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 11,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: Theme.colors.primary
}

const orderTypeText = {
    color: 'black',
    fontSize: 15,
    fontWeight: 500
}
const orderTypeTextSeleted = {
    color: 'white',
    fontSize: 15,
    fontWeight: 500
}


const initState = 1
const confirmState = 2
const successState = 3
const failState = 4

const orderTypeMap = {
    '1': 'Home Delivery',
    '2': 'Bus Gate',
    '3': 'Ninja Point',
    '4': 'Post Office'
}

const orderTypeLocalizationMap = {
    '1': 'home_delivery',
    '2': 'bus_gate',
    '3': 'ninja_point',
    '4': 'post_office'
}

const NJVLabel = ({ label }) => {
    return (
        <>
            <div style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</div>
        </>)
}
class NewAdvanceOrderForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pickUpDate: null,
            page: 1,
            excel_page: 1,
            currentStep: 0,
            tempLocation: null,
            userAddress_collection: [],
            receiverAddress_collection: [],
            pickupInformation: {},
            divisionList: [],
            townshipList: [],
            busGateList: [],
            partnershipStoreList: [],
            allDivisionList: [],
            deliveryChargesTemp: [],

            userInput_collection: [],
            orderState: initState,
            successfullOrderList: [],
            division_collection_home_delivery: [],
            township_map_home_delivery: {},
            division_collection_bus_gate_service: [],
            township_map_bus_gate_service: {},
            receiverAddress_collection_home_delivery: [],
            receiverAddress_collection_bus_gate_service: [],
            orderDetail: {},

            isFavouriteLocation: false,
            isUpdateData: false,
            downloadingExcel: false,
            isOrderTypeUpload: true,
            confirmToClearReceiverData: false,
            isDataFetching: false,

            openOrderDetailModal: false,
            openConfirmModal: false,
            openClearAllDataModal: false,
            openPickupModal: false,

            isFetchingSameDay: false,
            isFetchingPriceScript: false,
            isFetchingBusGate: false,
            isBusgateServiceAvailable: false,
            isExcelFileUploading: false,
            isFetchingPartnershipStore: false,
            isFetchingMyLocation: true,
            isFetchingDivision: false,

            is_postoffice_available: false,

            excel_order_list: [],
            failReasons: [],
            total_excel_role: 0,
            valid_excel_role: 0,
            invalid_excel_role: 0,
            fail_message_code: '076',

            isExitUserGroup: true,

            form_activity: { orderType: HOME_DELIVERY, favourite: false },
            is_insurance_checked: false,
            confirmRemoveInsuranceData: false
        }
        this.orderFormRef = React.createRef();
    }
    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 13).set('minute', 0).set('second', 0);
        const isBefore1PM = currentDate.isBefore(targetTime);
        if (isBefore1PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }
    disabledDate = (current) => {
        return current && current < this.getValidDate();
    };
    componentDidMount() {
        try {
            this.fetchCheckUserGroup()
            this.fetchReceiverLocation()
            this.fetchMyLocation()
            this.fetchDivision()
            this.fetchProfile();
            const todayDate = dayjs();
            const previousOrderList = this.props.advanceOrderDraftList

            const orderSessionTime = this.props.orderSessionTime
            if (previousOrderList && previousOrderList.length !== 0) {
                if (orderSessionTime) {
                    const totalMinuteDiff = todayDate.diff(orderSessionTime, 'minute');
                    if (totalMinuteDiff > 180) {
                        this.props.dispatch(clearOrder())
                        this.props.dispatch(updateOrderSession(todayDate))
                    } else {
                        this.setState({
                            userInput_collection: previousOrderList
                        })
                    }
                }
            } else {
                this.props.dispatch(updateOrderSession(todayDate))
            }
        } catch (error) {
            console.log(error)
        }
    }

    fetchCheckUserGroup = async () => {
        this.setState({
            isFetchingUserGroup: true
        })
        await ApiHandler({ url: Api.check_user_group, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON }).then(response => {
            this.setState({
                isExitUserGroup: response?.is_user_group_exist,
                isFetchingUserGroup: false
            })
        }).catch(error => {
            this.setState({
                isFetchingUserGroup: false
            })
        })
    }

    fetchMyLocation = async () => {

        try {
            const requestParams = { isMyLocation: true }

            const response = await ApiHandler({ url: Api.user_address_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })

            if (response) {
                let foundDefaultLocation = false
                let userAddress = {}
                let defaultLocationAddress = {}
                // response?.map((address) => {
                //     if (address.defaultLocation) {
                //         foundDefaultLocation = true
                //         userAddress = address
                //     }
                // })
                // if (!foundDefaultLocation && response.length !== 0) {
                //     userAddress = response[0]
                // }

                response?.map((address) => {
                    if (this.props.pickupAddress) {
                        if (address.township.id === this.props.pickupAddress?.townshipId) {
                            this.setState({
                                openPickupModal: false
                            })
                        }
                    } else {
                        if (address.defaultLocation) {
                            foundDefaultLocation = true;
                            defaultLocationAddress = address
                            userAddress = address;
                        }
                    }
                });
                if (!foundDefaultLocation && this.props.pickupAddress) {
                    userAddress = this.props.pickupAddress;
                } else if (!foundDefaultLocation && response.length !== 0) {
                    userAddress = response[0];
                }

                const pickupAddressInList = response.some(address => address.township.id === this.props.pickupAddress?.townshipId);

                if (pickupAddressInList) {
                    userAddress = this.props.pickupAddress;
                } else {
                    if (foundDefaultLocation) {
                        userAddress = defaultLocationAddress;
                    } else {
                        this.setState({
                            openPickupModal: true
                        })
                    }
                }
                if (userAddress?.township?.id) {
                    this.checkBusGateAvailableService(userAddress.township.id, userAddress)
                }
                this.setState({
                    userAddress_collection: response,
                    pickupInformation: { ...userAddress, 'townshipId': userAddress?.township?.id },
                })
            }
        } catch (error) {
            console.log(error)
        }
        this.setState({ isFetchingMyLocation: false })
    }
    fetchReceiverLocation = async () => {
        try {
            const requestParams = { isMyLocation: false }
            const response = await ApiHandler({ url: Api.user_address_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let receiverAddressHomedelivery = []
                let receiverAddressBusGateService = []
                response.map(userAddress => {
                    if (userAddress && userAddress.township && userAddress.township.division) {
                        if (userAddress.township.enableHomeDelivery && userAddress.township.division.enableHomeDelivery) {
                            receiverAddressHomedelivery.push(userAddress)
                        }
                        if (userAddress.township.enableBusGatePostOfficeService) {
                            receiverAddressBusGateService.push(userAddress)
                        }
                    }
                })
                this.setState({
                    receiverAddress_collection: receiverAddressHomedelivery,
                    receiverAddress_collection_bus_gate_service: receiverAddressBusGateService,
                    receiverAddress_collection_home_delivery: receiverAddressHomedelivery
                })
            }
        } catch (error) {
        }
    }
    checkBusGateAvailableService = async (id, userAddress) => {
        const { pickupInformation } = this.state
        let is_postoffice_available = false
        if (id === null || id === undefined) {
            return
        }
        await ApiHandler({ url: Api.check_bus_gate_service_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: { 'id': id } })
            .then((response) => {
                if ((userAddress?.township?.division?.shortCode === 'YGN' || userAddress?.township?.division?.shortCode === 'MDY') && response) {
                    is_postoffice_available = true
                } else if ((pickupInformation?.divisionShortCode === 'YGN' || pickupInformation?.divisionShortCode === 'MDY') && response) {
                    is_postoffice_available = true
                }
                this.setState({
                    isBusgateServiceAvailable: response,
                    is_postoffice_available
                })
            })
            .catch((error) => { })
    }
    fetchDivision = async () => {
        try {
            this.setState({
                isFetchingDivision: true
            })
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    division_collection: response,
                })
                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}
                let divisionListBusGateSevice = []
                let townshipMapBusGateService = {}
                response.map(division => {
                    if (division.active) {
                        divisionListBusGateSevice.push(division)
                        let townshipListBusGateService = []
                        division.townshipDTOList && division.townshipDTOList.map(township => {
                            if (township.active && township.enableBusGatePostOfficeService) {
                                townshipListBusGateService.push(township)
                            }
                        })
                        const sortedTownshipListBusGateService = townshipListBusGateService.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                        townshipMapBusGateService[division.id] = sortedTownshipListBusGateService
                    }

                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList && division.townshipDTOList.map(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        const sortedTownshipListHomeDelivery = townshipListHomeDelivery.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })
                        townshipMapHomeDelivery[division.id] = sortedTownshipListHomeDelivery
                    }
                })

                this.setState({
                    divisionList: divisionListHomeDelivery,
                    isFetchingDivision: false,

                    division_collection_home_delivery: divisionListHomeDelivery,
                    township_map_home_delivery: townshipMapHomeDelivery,

                    division_collection_bus_gate_service: divisionListBusGateSevice,
                    township_map_bus_gate_service: townshipMapBusGateService
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    fetchProfile = async () => {
        try {
            this.setState({
                isFetchingProfile: true
            })
            const response = await ApiHandler({ url: Api.shipper_profile, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            this.setState({
                senderInfo: response,
                isFetchingProfile: false
            });
        } catch (error) {
            console.log(error);
        }
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId, orderType, sameDay) => {
        this.setState({
            isFetchingPriceScript: true
        })
        const requestParams = {
            'senderTownshipId': senderTownshipId,
            'receiverTownshipId': receiverTownshipId,
            'orderType': orderTypeMap[orderType],
            'isSameDay': sameDay ? sameDay : false,
        }
        await ApiHandler({ url: Api.get_price, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                if (response || response === 0) {
                    this.orderFormRef.current.setFieldsValue({ deliveryFees: response })
                }
                this.setState({
                    isFetchingPriceScript: false
                })
            })
            .catch((error) => {
                this.setState({
                    isFetchingPriceScript: false
                })
            })
    }
    checkSameDay = async (senderTownshipId, receiverTownshipId) => {
        this.setState({
            isFetchingSameDay: true,
            isSameDayAvailable: false
        })
        const requestParams = {
            'fromTownshipId': senderTownshipId,
            'toTownshipId': receiverTownshipId,
        }
        await ApiHandler({ url: Api.order_check_same_day, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    isSameDayAvailable: response,
                    isFetchingSameDay: false
                })
            })
            .catch(() => {
                this.setState({
                    isFetchingSameDay: false
                })
            })
    }

    // pick up 
    handlePickupLocation = (data) => {
        const { form_activity, currentStep } = this.state
        if (currentStep !== 0) {
            this.setState({
                confirmToClearReceiverData: true,
                tempLocation: { ...data, 'id': data.value }
            })
        } else {
            this.setState({
                pickupInformation: data,
                form_activity: { orderType: HOME_DELIVERY, favourite: form_activity.favourite ? form_activity.favourite : false },
                isUpdateData: false
            }, () => this.checkBusGateAvailableService(data.townshipId))
        }
    }

    removingReceiverLocationConfirm = () => {
        const { tempLocation } = this.state

        this.props.dispatch(clearOrder())
        this.setState({
            pickupInformation: tempLocation,
            userInput_collection: [],
            tempLocation: null,
            confirmToClearReceiverData: false,
            form_activity: { orderType: HOME_DELIVERY, favourite: false },
            isUpdateData: false,
            busGateList: [],
            partnershipStoreList: [],
            isOrderTypeUpload: true,
            currentStep: 0,
            pickUpDate: null
        }, () => this.checkBusGateAvailableService(tempLocation?.townshipId))
        this.orderFormRef?.current?.setFieldsValue({
            address: null,
            deliveryFees: null,
            note: null,
            phoneNumber: null,
            townshipId: null,
            receiverName: null,
            divisionId: null,
            parcelAmount: 0,
            paymentOption: null,
            favouriteLocationId: null,
            orderType: HOME_DELIVERY,
            sameDay: false
        })
    }

    handlePickupDate = (value) => {
        const { pickupInformation } = this.state
        let pickUpInfo = {}
        let currentStep = 0
        if (value) {
            currentStep = 1
            pickUpInfo = { ...pickupInformation, 'pickupDate': value.format("YYYY-MM-DD") }
        } else {
            pickUpInfo = { ...pickupInformation }
        }
        this.setState({
            pickupInformation: pickUpInfo,
            pickUpDate: value,
            currentStep: currentStep
        })
    }

    fetchBusGate = async (senderTownshipId, receiverTownshipId) => {
        this.setState({
            isFetchingBusGate: true,
            busGateList: []
        })
        const requestParams = {
            'receiverTownshipId': receiverTownshipId,
            'senderLocationId': senderTownshipId
        }
        await ApiHandler({ url: Api.bus_gate_by_receiver_and_sender, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                if (response) {
                    this.setState({
                        busGateList: response,
                        isFetchingBusGate: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isFetchingBusGate: false
                })
            })

    }

    fetchPartnershipStore = async (receiverTownshipId, partnershipStoreId) => {
        this.setState({
            isFetchingPartnershipStore: true,
            partnershipStoreList: []
        })
        const requestParams = {
            'townshipId': receiverTownshipId
        }
        await ApiHandler({ url: Api.order_partnership_stores, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    partnershipStoreList: response ? response : [],
                    isFetchingPartnershipStore: false
                })

            })
            .catch((error) => {
                this.setState({
                    isFetchingPartnershipStore: false
                })
            })

        if (partnershipStoreId) {
            this.orderFormRef.current.setFieldsValue({ partnershipStoreId: partnershipStoreId })
        }
    }

    clearAllUserOrder = () => {
        const { form_activity } = this.state
        this.orderFormRef?.current?.setFieldsValue({
            address: null,
            deliveryFees: null,
            note: null,
            phoneNumber: null,
            townshipId: null,
            receiverName: null,
            divisionId: null,
            parcelAmount: 0,
            paymentOption: null,
            favouriteLocationId: null,
            orderType: HOME_DELIVERY,
            sameDay: false

        })
        this.setState({
            form_activity: { orderType: HOME_DELIVERY, favourite: form_activity.favourite ? form_activity.favourite : false },
            isUpdateData: false,
            userInput_collection: [],
            pickUpDate: null,
            busGateList: [],
            partnershipStoreList: [],
            currentStep: 0,
            isOrderTypeUpload: true,
            openClearAllDataModal: false
        })

        this.props.dispatch(clearOrder())
    }

    clearInput = () => {
        const { form_activity } = this.state
        this.orderFormRef?.current?.setFieldsValue({
            address: null,
            deliveryFees: null,
            note: null,
            phoneNumber: null,
            townshipId: null,
            receiverName: null,
            divisionId: null,
            parcelAmount: 0,
            paymentOption: null,
            favouriteLocationId: null,
            orderType: HOME_DELIVERY,
            sameDay: false,
        })

        this.setState({
            form_activity: { orderType: HOME_DELIVERY, favourite: form_activity.favourite ? form_activity.favourite : false },
            isUpdateData: false,
            receiverInfo: null,
            insuranceInfo: null,
            is_insurance_checked: false
        })
    }

    handleAddReceiver = (formData) => {
        const { form_activity, isFetchingPriceScript, userInput_collection, pickupInformation, insuranceInfo } = this.state

        const uid = uuidv4()
        let isUpdate = false
        let currentReceiverData = {}
        let updatedDeliveryFees = 0;
        currentReceiverData = { ...form_activity }
        currentReceiverData = { ...currentReceiverData, ...formData }
        if (currentReceiverData.orderType === NINJA_POINT) {
            currentReceiverData = { ...currentReceiverData, address: currentReceiverData.partnershipStoreAddress }
        }
        if (currentReceiverData.id === null || currentReceiverData.id === '' || currentReceiverData.id === undefined) {
            currentReceiverData = { ...currentReceiverData, id: uid, key: uid }
        } else {
            isUpdate = true
        }
        if ((form_activity.orderType === HOME_DELIVERY || form_activity.orderType === NINJA_POINT) && isFetchingPriceScript) {
            message.error("Please wait price script is fetching")
            return
        }
        currentReceiverData = {
            ...currentReceiverData,
            parcelAmount: formData.parcelAmount ? +formData.parcelAmount : 0,
            deliveryFees: formData.deliveryFees ? +formData.deliveryFees : 0,
        }
        let total = 0
        if ((currentReceiverData.orderType === HOME_DELIVERY || currentReceiverData.orderType === NINJA_POINT)) {
            if (currentReceiverData.paymentOption === 1) {
                total = Number(formData.parcelAmount) + Number(formData.deliveryFees)
            } else if (currentReceiverData.paymentOption === 2) {
                total = Number(formData.parcelAmount)
            }

        }
        // currentReceiverData = { ...currentReceiverData, total }

        if (insuranceInfo) {
            currentReceiverData = { ...currentReceiverData, total, isExistInsurance: insuranceInfo?.isExistInsurance, insuranceInfo };
        } else {
            currentReceiverData = { ...currentReceiverData, total, isExistInsurance: false, insuranceInfo: null };
        }

        let new_userinput_collection = []
        if (isUpdate) {
            new_userinput_collection = userInput_collection.map(oldReceiverData => {
                if (oldReceiverData.id === currentReceiverData.id) {
                    // new_userinput_collection = [...new_userinput_collection, currentReceiverData]
                    return currentReceiverData
                } else {
                    // new_userinput_collection = [...new_userinput_collection, oldReceiverData]
                    return oldReceiverData
                }
            })
        } else {
            if (userInput_collection)
                new_userinput_collection = [...userInput_collection, currentReceiverData]
            else
                new_userinput_collection = [currentReceiverData]
        }
        new_userinput_collection.map(data => {
            updatedDeliveryFees = data?.deliveryFees
        })
        if (updatedDeliveryFees === 0) {
            message.destroy()
            message.error(`You can't ${isUpdate ? 'Update' : 'Add'} order if delivery fees 0`)
            return
        }

        this.setState(prev => ({
            userInput_collection: new_userinput_collection,
            form_activity: { favourite: form_activity.favourite, orderType: HOME_DELIVERY },
            isUpdateData: false,
            busGateList: [],
            townshipList: [],
            partnershipStoreList: [],
            insuranceInfo: null,
            is_insurance_checked: false,
            confirmRemoveInsuranceData: true,
            receiverInfo: {
                ...prev.receiverInfo,
                receiverName: null,
                receiverPhone: null,
                divisionId: null,
                townshipId: null,
                receiverAddress: null,
                favouriteLocationId: null
            }
        }))

        this.orderFormRef.current.setFieldsValue({
            orderType: HOME_DELIVERY,
            address: null,
            deliveryFees: null,
            note: null,
            phoneNumber: null,
            townshipId: null,
            receiverName: null,
            divisionId: null,
            parcelAmount: 0,
            paymentOption: null,
            favouriteLocationId: null,
            sameDay: false,
            partnershipStoreId: null
        })
        if (isUpdate) {
            message.success(`${i18next.t("success_msg_new_receiver_updated")}`)
        } else {
            message.success(`${i18next.t("success_msg_new_receiver_added")}`)
        }
        this.props.dispatch(updateAdvanceOrderList({ orderList: new_userinput_collection, pickupAddress: pickupInformation }))
    }

    deleteOrder = (uid) => {
        if (this.state?.isUpdateData) {
            message.destroy()
            message.error("You can't delete the receiver while editing their information.")
            return
        }
        const { userInput_collection, page } = this.state
        let new_user_collection = []
        let newPage = page
        userInput_collection.map(value => {
            if (value.id !== uid) {
                new_user_collection = [...new_user_collection, value]
            }
        })
        if (new_user_collection.length === 0) {
            newPage = 1
        } else if ((new_user_collection.length / 10) + 1 === page) {
            newPage = page - 1
        }
        this.setState({
            userInput_collection: new_user_collection,
            page: newPage
        })
        this.props.dispatch(updateAdvanceOrderList(new_user_collection))
    }

    editOrder = (uid) => {
        const { userInput_collection, township_map_home_delivery, township_map_bus_gate_service, currentStep, pickupInformation, receiverAddress_collection_home_delivery } = this.state
        let editOrder = {}
        userInput_collection.map(value => {
            if (value.id === uid) {
                editOrder = value
                return
            }
        })
        let townshipList = []
        if (editOrder.orderType === HOME_DELIVERY || editOrder.orderType === NINJA_POINT) {
            townshipList = township_map_home_delivery[editOrder.divisionId]
        } else {
            if (editOrder.orderType === BUS_GATE) {
                this.fetchBusGate(pickupInformation.townshipId, editOrder.townshipId)
            }
            townshipList = township_map_bus_gate_service[editOrder.divisionId]
        }
        if (editOrder.orderType === NINJA_POINT) {
            this.fetchPartnershipStore(editOrder.townshipId, editOrder.partnershipStoreId)
        }
        this.orderFormRef.current.setFieldsValue({ ...editOrder, partnershipStoreId: null })
        const favouriteLocation = receiverAddress_collection_home_delivery.find(address => address.id === editOrder.favouriteLocationId)

        const receiverInfo = {
            "favouriteLocationId": {
                "receiverName": favouriteLocation?.name,
                "address": favouriteLocation?.address1,
                "divisionName": favouriteLocation?.township.division.name,
                "townshipName": favouriteLocation?.township.name,
            },
            "favourite": editOrder.favourite,
            "receiverName": editOrder.receiverName,
            "townshipId": {
                label: editOrder.townshipName
            },
            "divisionId": {
                label: editOrder.divisionName
            },
            "receiverAddress": editOrder.address
        }

        this.setState({
            form_activity: editOrder,
            isUpdateData: true,
            receiverInfo,
            insuranceInfo: editOrder.insuranceInfo,
            townshipList,
            is_insurance_checked: editOrder.isExistInsurance,
            currentStep: currentStep === 1 ? currentStep : 1,
        })
    }

    confirm = () => {
        this.setState({
            openConfirmModal: true,
            currentStep: 2
        })
    }

    viewOrderDetail = (orderDetail) => {
        this.setState({
            orderDetail,
            openOrderDetailModal: true
        })
    }

    createOrderWithFormData = async () => {
        const { userInput_collection, pickupInformation, excel_order_list, isOrderTypeUpload, fail_message_code, form_activity } = this.state

        this.setState({
            orderState: confirmState
        })
        if (isOrderTypeUpload) {
            try {
                let formData = new FormData()
                userInput_collection.map((receiver, index) => {
                    let addressDetail = receiver.address
                    if (receiver.orderType === BUS_GATE) {
                        let note = ""
                        if (receiver.note !== null && receiver.note !== undefined) {
                            note = receiver.note ? "(" + receiver.note + ")" : ""
                        }
                        receiver = { ...receiver, note: receiver.busGateFees ? 'Pay Bus Gate Fees ' + note : receiver.note }
                        addressDetail = "(Bus Gate) " + receiver.busGateName
                    }

                    formData.append(`receiverDTOS[${index}].isFavourite`, receiver.favourite)
                    formData.append(`receiverDTOS[${index}].favouriteLocationId`, receiver.favouriteLocationId ? receiver.favouriteLocationId : 0)
                    formData.append(`receiverDTOS[${index}].receiverName`, receiver.receiverName)
                    formData.append(`receiverDTOS[${index}].phoneNumber`, receiver.phoneNumber)
                    formData.append(`receiverDTOS[${index}].addressDetail`, addressDetail)
                    formData.append(`receiverDTOS[${index}].orderType`, receiver.orderType ? orderTypeMap[receiver.orderType] : orderTypeMap[HOME_DELIVERY])
                    formData.append(`receiverDTOS[${index}].paymentOption`, receiver.orderType === HOME_DELIVERY ? receiver.paymentOptionName : PAY_BY_SHIPPER)
                    formData.append(`receiverDTOS[${index}].isSameDay`, receiver.sameDay ? receiver.sameDay : false)
                    formData.append(`receiverDTOS[${index}].parcelAmount`, receiver.parcelAmount)
                    formData.append(`receiverDTOS[${index}].codAmount`, receiver.total)
                    formData.append(`receiverDTOS[${index}].deliveryCharges`, receiver.deliveryFees)
                    formData.append(`receiverDTOS[${index}].note`, receiver.note && receiver.insuranceInfo?.insuranceFees ? `${receiver.note || ''} (FNI Insuranceဖြင့်အာမခံဝယ်ယူထားပါသည်။) ` : receiver.note ? receiver.note : '')
                    formData.append(`receiverDTOS[${index}].townShipId`, receiver.townshipId)
                    formData.append(`receiverDTOS[${index}].busGateId`, receiver.busGateId ? receiver.busGateId : 0)
                    formData.append(`receiverDTOS[${index}].partnershipStoreId`, receiver.partnershipStoreId ? receiver.partnershipStoreId : 0)
                    if (receiver?.insuranceInfo?.insuranceItems) {
                        receiver?.insuranceInfo?.insuranceItems.forEach((product, productIndex) => {
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].name`, product.name)
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].price`, product.price)
                            formData.append(`receiverDTOS[${index}].insuranceItems[${productIndex}].quantity`, product.quantity)
                        })
                    }
                    if (receiver?.insuranceInfo?.insuranceFees) {
                        formData.append(`receiverDTOS[${index}].insuranceFees`, receiver?.insuranceInfo?.insuranceFees)
                    }
                })
                formData.append('pickUpLocation', pickupInformation?.id)
                formData.append('pickUpDate', pickupInformation?.pickupDate)
                formData.append('pickUpInstruction', form_activity.pickUpInstruction ? form_activity.pickUpInstruction : '');
                formData.append('isAdmin', false)

                const response = await ApiHandler({ url: Api.order_create, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: formData })
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        busGateList: [],
                        partnershipStoreList: [],
                        successfullOrderList: response.data
                    })
                    this.clearAllUserOrder()
                }
            } catch (error) {
                let code = fail_message_code
                if (error && error.response && error.response.data && error.response.data.code) {
                    code = error.response.data.code
                }
                this.setState({
                    orderState: failState,
                    fail_message_code: code
                })
            }
        } else {
            try {
                let receiverList = []
                excel_order_list.map((receiver, index) => {
                    let receiverJson = {
                        'isFavourite': false,
                        'receiverName': receiver.receiverName,
                        'phoneNumber': receiver.phoneNumber,
                        'orderType': receiver.orderType,
                        'addressDetail': receiver.addressDetail,
                        'isSameDay': false,
                        'parcelAmount': receiver.parcelAmount,
                        'paymentOption': receiver.paymentOption,
                        'deliveryCharges': receiver.deliveryCharges,
                        'note': receiver.note,
                        'townShipId': receiver.townShipId,
                        'codAmount': receiver.codAmount
                    }
                    receiverList.push(receiverJson)
                })
                let jsonData = {
                    'pickUpLocation': pickupInformation.id,
                    'pickUpDate': pickupInformation.pickupDate,
                    'isAdmin': false,
                    'receiverDTOS': receiverList
                }
                const response = await ApiHandler({ url: Api.excel_order_create, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: jsonData })
                if (response && response.status === 200) {
                    this.setState({
                        orderState: successState,
                        currentStep: 0,
                        userInput_collection: [],
                        busGateList: [],
                        partnershipStoreList: [],
                        successfullOrderList: response.data,
                        excel_order_list: [],
                        valid_excel_role: 0,
                        invalid_excel_role: 0
                    })
                    this.clearAllUserOrder()
                }
            } catch (error) {
                console.log(error)
                let code = fail_message_code
                if (error && error.response && error.response.data && error.response.data.code) {
                    code = error.response.data.code
                }
                // this.setState({
                //     orderState: failState,
                //     fail_message_code: code
                // })
                this.setState({
                    orderState: successState,
                })
            }
        }
    }
    onChangePagination = (currentPage) => {
        this.setState({
            page: currentPage.current
        })
    }

    onChangeExcelPagination = (page, size) => {
        this.setState({
            excel_page: page
        })
    }

    GoToHistory = () => {
        this.setState({ openConfirmModal: false, orderState: initState })
        this.props.navigate(CustomPath.order_history)
    }

    handleSegment = () => {
        this.setState({
            isOrderTypeUpload: !this.state.isOrderTypeUpload
        })
    }

    airwaybill = () => {
        this.props.navigate(CustomPath.airwaybill, { state: { data: this.state.successfullOrderList, route: CustomPath.order_create } })
    }

    downloadExampleTemplate = async () => {
        this.setState({
            downloadingExcel: true
        })
        try {
            const response = await DownloadOrderFormatExcel()
            if (response && response.status === 200) {
                this.setState({
                    downloadingExcel: false
                })
            }
        } catch (error) {
            this.setState({
                downloadingExcel: false
            })
        }
    }

    handleFileUpload = async (file) => {
        const { pickupInformation } = this.state
        this.setState({
            isExcelFileUploading: true
        })
        try {
            if (pickupInformation && pickupInformation.townshipId) {
                let formData = new FormData()
                formData.append('excel', file);
                formData.append('id', pickupInformation.townshipId);
                const response = await ApiHandler({ url: Api.upload_order_excel, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
                if (response && response.data) {
                    let list = []
                    let valid = 0
                    let invalid = 0
                    response.data.map((order, index) => {
                        order = {
                            ...order,
                            key: index + 1,
                            parcelAmount: order.parcelAmount ? parseInt(order.parcelAmount) : 0,
                            deliveryCharges: order.deliveryCharges ? parseInt(order.deliveryCharges) : 0,

                        }
                        if (order.paymentOption === COLLECT_FROM_CUSTOMER) {
                            order = { ...order, codAmount: order.parcelAmount + order.deliveryCharges }
                        } else if (order.paymentOption === PAY_BY_SHIPPER) {
                            order = { ...order, codAmount: order.parcelAmount }
                        }
                        list.push({ ...order, id: uuidv4() })
                        if (order.confirm) {
                            valid = valid + 1
                        } else {
                            invalid = invalid + 1
                        }
                    })
                    this.setState({
                        excel_order_list: list,
                        valid_excel_role: valid,
                        invalid_excel_role: invalid
                    })
                }
            }
        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isExcelFileUploading: false
        })
    }

    removeInvalid = (id) => {
        const { excel_order_list, invalid_excel_role } = this.state
        let new_excel_order_list = []
        let invalid_role = 0;
        excel_order_list && excel_order_list.map(order => {
            if (order.id !== id) {
                new_excel_order_list.push(order)
            } else if (order.id === id) {
                if (invalid_excel_role !== 0) {
                    invalid_role = invalid_excel_role - 1
                }
            }
        })
        this.setState({
            invalid_excel_role: invalid_role,
            excel_order_list: new_excel_order_list
        })

    }

    uploadNewExcel = () => {
        this.setState({
            invalid_excel_role: 0,
            valid_excel_role: 0,
            excel_order_list: []
        })
    }

    onTownshipFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    handleActivity = (key, formData) => {
        let { form_activity, pickupInformation, township_map_home_delivery, township_map_bus_gate_service } = this.state
        if (key === 'divisionId') {
            let townshipList = []
            let delivery_fees = 0
            if (form_activity.orderType === HOME_DELIVERY || form_activity.orderType === NINJA_POINT) {
                form_activity = { ...form_activity, 'deliveryFees': 0 }
                townshipList = township_map_home_delivery[formData.value]
            } else if (form_activity.orderType === BUS_GATE || form_activity.orderType === POST_OFFICE) {
                delivery_fees = DELIVERY_FEES
                townshipList = township_map_bus_gate_service[formData.value]
            }
            this.orderFormRef.current.setFieldsValue({ townshipId: null })
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'townshipId': null, 'townshipName': null, 'busGateId': null, 'busGateName': null, 'divisionName': formData.label, 'deliveryFees': delivery_fees },
                townshipList
            }));
        } else if (key === 'townshipId') {
            if (form_activity.orderType === HOME_DELIVERY) {
                this.checkSameDay(pickupInformation.townshipId, formData.value)
                this.getPriceScript(pickupInformation.townshipId, formData.value, form_activity.orderType)
                this.orderFormRef.current.setFieldsValue({ sameDay: false, 'deliveryFees': 0 })
            } else if (form_activity.orderType === BUS_GATE) {
                this.fetchBusGate(pickupInformation.townshipId, formData.value)
            } else if (form_activity.orderType === NINJA_POINT) {
                this.fetchPartnershipStore(formData.value)
                this.getPriceScript(pickupInformation.townshipId, formData.value, form_activity.orderType)
            }
            this.orderFormRef.current.setFieldsValue({ busGateId: null, 'partnershipStoreId': null, 'partnershipStoreName': null, 'partnershipStoreAddress': null })
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'townshipId': formData.value, 'townshipName': formData.label, 'busGateId': null, 'busGateName': null }
            }));
        } else if (key === 'orderType') {
            let delivery_fees = 0
            let payment_option = null
            const { receiverAddress_collection_bus_gate_service, receiverAddress_collection_home_delivery } = this.state;
            let loyalCustomers_collection = [];
            if (formData === BUS_GATE || formData === POST_OFFICE) {
                delivery_fees = DELIVERY_FEES
                payment_option = 2
                this.orderFormRef.current.setFieldsValue({ 'paymentOption': payment_option, 'deliveryFees': delivery_fees, 'townshipId': null, 'divisionId': null, 'townshipName': null })
                loyalCustomers_collection = receiverAddress_collection_bus_gate_service
            } else if (formData === HOME_DELIVERY || formData === NINJA_POINT) {
                this.orderFormRef.current.setFieldsValue({ 'paymentOption': payment_option, 'deliveryFees': 0, 'townshipId': null, 'divisionId': null, 'townshipName': null })
                loyalCustomers_collection = receiverAddress_collection_home_delivery
            }

            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'paymentOption': payment_option, [key]: formData },
                insuranceInfo: null,
                is_insurance_checked: false,
                busGateList: [],
                townshipList: [],
                partnershipStoreList: [],
                receiverAddress_collection: loyalCustomers_collection
            }));
            this.orderFormRef.current.setFieldsValue({ 'divisionId': null, 'townshipId': null, address: null, busGateId: null, partnershipStoreId: null, favouriteLocationId: null })
        } else if (key === 'busGateId') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'busGateId': formData.value, 'busGateName': formData.label }
            }));
        } else if (key === 'partnershipStoreId') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'partnershipStoreId': formData.value, 'partnershipStoreName': formData.label, 'partnershipStoreAddress': formData.address }
            }));
        } else if (key === 'paymentOption') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, paymentOption: formData, 'paymentOptionName': formData === 1 ? COLLECT_FROM_CUSTOMER : PAY_BY_SHIPPER }
            }));
        } else if (key === 'favourite') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'favourite': formData }
            }));
        } else if (key === 'favouriteLocationId') {
            if (form_activity.orderType === HOME_DELIVERY) {
                this.checkSameDay(pickupInformation.townshipId, formData.townshipId)
                this.getPriceScript(pickupInformation.townshipId, formData.townshipId, form_activity.orderType)
                this.orderFormRef.current.setFieldsValue({ sameDay: false })
            } else if (form_activity.orderType === BUS_GATE) {
                this.fetchBusGate(pickupInformation.townshipId, formData.townshipId)
            } else if (form_activity.orderType === NINJA_POINT) {
                this.fetchPartnershipStore(formData.townshipId)
                this.getPriceScript(pickupInformation.townshipId, formData.townshipId, form_activity.orderType)
                this.orderFormRef.current.setFieldsValue({ partnershipStoreId: null })
            }
            this.orderFormRef.current.setFieldsValue({ busGateId: null })
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, ...formData }
            }));
        } else if (key === 'sameDay') {
            if (formData)
                this.getPriceScript(pickupInformation.townshipId, form_activity.townshipId, form_activity.orderType, true)
            else
                this.getPriceScript(pickupInformation.townshipId, form_activity.townshipId, form_activity.orderType)
        }else if(key === 'pickUpInstruction') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, pickUpInstruction: formData}
            }));
        }

        if (key === 'receiverName' || key === 'receiverPhone' || key === 'divisionId' || key === 'townshipId' || key === 'receiverAddress' || key === 'favourite' || key === 'favouriteLocationId') {

            let receiverData;
            if (key !== 'favourite') {
                receiverData = {
                    [key]: formData,
                    favourite: key === 'favouriteLocationId' ? true : false,
                }
            } else {
                receiverData = { [key]: formData }
            }

            this.setState((prev) => {
                const updatedReceiverInfo = {
                    ...prev.receiverInfo,
                    ...receiverData,
                };

                return {
                    receiverInfo: updatedReceiverInfo,
                    confirmRemoveInsuranceData: false,
                };
            });
        }
    }

    changeParcelAmount = (inputValue) => {
        if (inputValue && inputValue[0] === '0') {
            inputValue = inputValue.slice(1);
        }
        if (inputValue === '') {
            inputValue = 0
        }
        this.orderFormRef?.current?.setFieldsValue({ parcelAmount: inputValue })
    }

    handleReceiverData = (updatedFields) => {
        this.orderFormRef?.current?.setFieldsValue(updatedFields)
    }

    handleInsuranceData = (data) => {
        this.setState({ insuranceInfo: data })
    }

    handleInsuranceCheckbox = (checked) => {
        this.setState({
            is_insurance_checked: checked
        })
    }

    handleRemoveStatus = (value) => {
        this.setState({
            confirmRemoveInsuranceData: value
        })
    }

    render() {
        const { openFailReasonModal, failReasons, isFetchingMyLocation, isFetchingPriceScript, isFetchingBusGate, isSameDayAvailable, fail_message_code, openOrderDetailModal, valid_excel_role, invalid_excel_role, downloadingExcel, isDataFetching, confirmToClearReceiverData, userAddress_collection, receiverAddress_collection, currentStep, isUpdateData, openConfirmModal, orderState,
            pickupInformation, userInput_collection, isFetchingPartnershipStore, townshipList, divisionList, page, pickUpDate, busGateList, partnershipStoreList, isOrderTypeUpload, isBusgateServiceAvailable, excel_order_list, isExcelFileUploading, excel_page, form_activity, orderDetail, openClearAllDataModal, is_postoffice_available, isExitUserGroup, isFetchingUserGroup, isFetchingDivision, openPickupModal, senderInfo, receiverInfo, is_insurance_checked, confirmRemoveInsuranceData } = this.state

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };
        const mobile_columns = [
            {
                title: <>{i18next.t("receiver")}</>,
                dataIndex: 'receiverName',
                key: 'receiverName'
            },
            {
                title: <>{i18next.t("phone_number")}</>,
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, order) => (
                    <div>
                        <NJVButton size={'middle'} type="primary" shape="circle" style={{ marginBottom: 5 }} onClick={() => this.editOrder(order.id)}><EditOutlined /></NJVButton><br />
                        <NJVButton size={'middle'} type="primary" shape="circle" onClick={() => this.deleteOrder(order.id)} danger><DeleteOutlined /></NJVButton>
                    </div>
                ),
            },
        ]
        const items = [
            {
                'key': 1,
                'title': <span style={stepLabelStyle}>{i18next.t("sender_info")}</span>
            },
            {
                'key': 2,
                'title': <span style={stepLabelStyle}>{i18next.t("receiver_info")}</span>
            },
            {
                'key': 1,
                'title': <span style={stepLabelStyle}>{i18next.t("create_parcel")}</span>
            }
        ]
        let columns = [
            {
                title: <>{i18next.t("no")}</>,
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * 10 + index + 1}</span>,
                width: 5
            },
            {
                title: <>{i18next.t("receiver_name")}</>,
                dataIndex: 'receiverName',
                key: 'receiverName'
            },
            {
                title: <>{i18next.t("receiver_phone_number")}</>,
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: <>{i18next.t("township_city")}</>,
                dataIndex: 'townshipName',
                key: 'townshipName'

            },
            {
                title: <>{i18next.t("parcel_value")}</>,
                dataIndex: 'parcelAmount',
                key: 'parcelAmount'
            },
            {
                title: <>{i18next.t("delivery_fees")}</>,
                dataIndex: 'deliveryFees',
                key: 'deliveryFees',
            },
            {
                title: <>{i18next.t("cod")}</>,
                dataIndex: 'total',
                key: 'total'
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, order) => (
                    <Space>
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"

                            menu={{
                                items: [
                                    {
                                        label: <div onClick={() => this.viewOrderDetail(order)} style={{ display: 'flex', alignItems: 'center', width: 85 }}><div style={{ width: 7, height: 15, backgroundColor: 'green', borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("view")}</span></div>,
                                        key: '0',
                                    },
                                    {
                                        label: <div onClick={() => this.editOrder(order.id)} style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: 7, height: 15, backgroundColor: Theme.colors.system_blue, borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("edit")}</span></div>,
                                        key: '1',
                                    },
                                    {
                                        label: <div onClick={() => this.deleteOrder(order.id)} style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: 7, height: 15, backgroundColor: 'red', borderRadius: 10, marginRight: 8 }}></div><span>{i18next.t("delete")}</span></div>,
                                        key: '2',
                                    }
                                ]
                            }}
                        >
                            <div style={{ width: 30, height: 30, background: '#1677ff', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <MenuOutlined style={{ color: 'white' }} />
                            </div>
                        </Dropdown>
                    </Space >
                ),
                width: 10
            },
        ]

        let excel_order_columns = [
            {
                title: <>{i18next.t("no")}</>,
                key: '1',
                render: (value, item, index) => <span>{(excel_page - 1) * 50 + index + 1}</span>,
                width: 5
            },
            {
                title: <>{i18next.t("receiver_name")}</>,
                dataIndex: 'receiverName',
                key: '2'
            },
            {
                title: <>{i18next.t("receiver_phone_number")}</>,
                dataIndex: 'phoneNumber',
                key: '3',
                width: 10
            },
            {
                title: <>{i18next.t("township_city")}</>,
                dataIndex: 'townShip',
                key: '4',

            },
            {
                title: <>{i18next.t("parcel_value")}</>,
                dataIndex: 'parcelAmount',
                key: '5',
                width: 30
            },
            {
                title: <>{i18next.t("delivery_fees")}</>,
                dataIndex: 'deliveryCharges',
                key: '6',
                width: 30
            },
            {
                title: <>{i18next.t("cod")}</>,
                dataIndex: 'codAmount',
                key: '7',
                width: 30
            }, {
                title: <>{i18next.t("status")}</>,
                key: '8',
                width: 10,
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <span style={{ color: Colors.darkGreen }}>Valid</span>
                                :
                                <>
                                    <span style={{ color: Colors.red }}>Invalid</span>
                                    <Button
                                        size='small'
                                        type='primary' shape='circle' onClick={() => this.setState({ openFailReasonModal: true, failReasons: order.failReasons })}>
                                        <QuestionOutlined color='#000000' />
                                    </Button>
                                </>
                        }

                    </Space>
                ),
            }, {
                title: 'Action',
                key: '9',
                render: (_, order) => (
                    <Space>
                        {
                            order.confirm ?
                                <></>
                                :
                                <NJVButton size={'middle'} type="primary" shape="circle" onClick={() => this.removeInvalid(order.id)} danger><DeleteOutlined /></NJVButton>
                        }

                    </Space>
                ),
                width: 10
            },
        ]

        return (
            <>
                <Modal
                    title={i18next.t("confirmation")}
                    centered
                    open={confirmToClearReceiverData}
                    onCancel={() => this.setState({ confirmToClearReceiverData: false, tempLocation: null })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>{i18next.t("msg_action_will_delete_receiver_data")}</p>
                    <NJVButton type='primary' style={{ marginRight: 10 }} onClick={() => this.setState({ confirmToClearReceiverData: false, tempLocation: null })}>{i18next.t("cancel")}</NJVButton><NJVButton type='primary' style={{ backgroundColor: 'red' }} onClick={() => this.removingReceiverLocationConfirm()}>{i18next.t("clear")}</NJVButton>
                </Modal>
                <Modal
                    title={i18next.t("confirmation")}
                    centered
                    open={openClearAllDataModal}
                    onCancel={() => this.setState({ openClearAllDataModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>{i18next.t("msg_action_will_delete_receiver_data")}</p>
                    <NJVButton type='primary' style={{ marginRight: 10 }} onClick={() => this.setState({ openClearAllDataModal: false })}>{i18next.t("cancel")}</NJVButton><NJVButton type='primary' style={{ backgroundColor: 'red' }} onClick={() => this.clearAllUserOrder()}>{i18next.t("clear")}</NJVButton>
                </Modal>
                <Modal
                    title={i18next.t("confirmation")}
                    centered
                    open={openPickupModal}
                    onCancel={() => this.setState({ openPickupModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                    maskClosable={false}
                >
                    <p>Your data can't get anymore becasue of pervious pickup location isn't exist</p>
                    <NJVButton type='primary' style={{ marginRight: 10 }} onClick={() => {
                        this.props.dispatch(clearOrder())
                        this.setState({ openPickupModal: false })
                        window.location.reload()
                    }}>OK</NJVButton>
                </Modal>
                <Modal
                    title={i18next.t("fail_reason")}
                    centered
                    open={openFailReasonModal}
                    onCancel={() => this.setState({ openFailReasonModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        failReasons && failReasons.map((item) => <div>{item}</div>)
                    }
                    <NJVButton type='primary' style={{ marginTop: 10 }} onClick={() => this.setState({ openFailReasonModal: false })}>{i18next.t("ok")}</NJVButton>
                </Modal >
                <Modal
                    title={i18next.t("order_detail")}
                    centered
                    open={openOrderDetailModal}
                    onCancel={() => this.setState({ openOrderDetailModal: false, orderDetail: {} })}
                    footer={null}
                    className='custom-modal'
                >
                    <Row style={{ marginTop: 30 }}>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("receiver_name")} />
                            <div>{orderDetail.receiverName}</div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                            <div>{orderDetail.phoneNumber}</div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("division_state")} />
                            <div>{orderDetail.divisionName}</div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("township_city")} />
                            <div>{orderDetail.townshipName}</div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={24}>
                            <NJVLabel label={i18next.t("address")} />
                            <div>{orderDetail.orderType === BUS_GATE ? `${orderDetail.busGateName}(Bus Gate)` : orderDetail.address}</div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("order_type")} />
                            <div>
                                {
                                    orderDetail && orderDetail.orderType && <>{i18next.t(orderTypeLocalizationMap[orderDetail.orderType])}</>
                                }
                            </div>
                        </Col>
                        <Col span={12}>
                            <NJVLabel label={i18next.t("payment_option")} />
                            <div>
                                {
                                    orderDetail && orderDetail.paymentOptionName === COLLECT_FROM_CUSTOMER ?
                                        <>{i18next.t("collect_from_customer")}</>
                                        :
                                        <>{i18next.t("pay_by_shipper")}</>
                                }
                            </div>
                        </Col>
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={8}>
                            <NJVLabel label={i18next.t("parcel_value")} />
                            <div>{orderDetail.parcelAmount}</div>
                        </Col>

                        <Col span={9}>
                            <NJVLabel label={i18next.t("delivery_fees")} />
                            <div>{orderDetail.deliveryFees}</div>
                        </Col>
                        <Col span={7}>
                            <NJVLabel label={i18next.t("cod")} />
                            <div>{orderDetail.total}</div>
                        </Col>

                        {
                            orderDetail.sameDay ?
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={24} >
                                        <span style={{ fontWeight: '500' }}>{i18next.t("same_day")}</span>
                                    </Col>
                                </>
                                : orderDetail.busGateFees ?
                                    <>
                                        <Col span={24}>
                                            <Divider style={{ margin: '13px 0px' }} />
                                        </Col>
                                        <Col span={24} >
                                            <span style={{ fontWeight: '500' }}>{i18next.t("pay_bus_gate_fees")}</span>
                                        </Col>
                                    </>
                                    :
                                    <></>
                        }
                        {
                            orderDetail.note || orderDetail.isExistInsurance ?
                                <>
                                    <Col span={24}>
                                        <Divider style={{ margin: '13px 0px' }} />
                                    </Col>
                                    <Col span={24}>
                                        <NJVLabel label={i18next.t("note")} />
                                        <div>{orderDetail.isExistInsurance && '(FNI Insuranceဖြင့်အာမခံဝယ်ယူထားပါသည်။)'}{orderDetail.note}</div>
                                    </Col>
                                </>

                                :
                                <></>
                        }
                        <Col span={24}>
                            <Divider style={{ margin: '13px 0px' }} />
                        </Col>
                        <Col span={24}>
                            <Checkbox checked={orderDetail.isExistInsurance}>Purchase Insurance</Checkbox>
                        </Col>
                        <Col span={24} style={{ textAlign: 'center', marginTop: 50 }}>
                            <Button
                                onClick={() => this.setState({ openOrderDetailModal: false, orderDetail: {} })}
                                type='primary' style={{ backgroundColor: Theme.colors.primary }}>{i18next.t("close")}</Button>
                        </Col>

                    </Row>
                </Modal>
                <Modal
                    title={null}
                    centered
                    open={openConfirmModal}
                    onOk={() => this.createOrderWithFormData()}
                    onCancel={() => this.setState({
                        openConfirmModal: false,
                        orderState: initState

                    })}
                    footer={null}
                    closeIcon={orderState === successState ? true : null}
                    className='custom-modal'
                >
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {
                                orderState === initState ?
                                    <>
                                        {/* <Lottie
                                            style={{ height: '200px' }}
                                            animationData={ShippingAnimation}
                                            loop={true}
                                            autoplay={true}
                                        /> */}
                                        <div style={{ fontSize: 15, fontWeight: '500', marginBottom: 20 }}>{i18next.t("instruction_title")}</div>
                                        <NJVInput
                                            bgcolor={Theme.colors.light_gray}
                                            size="large"
                                            showCount
                                            onChange={(e) => this.handleActivity('pickUpInstruction', e.target.value)}
                                            style={{
                                                width: '100%',
                                            }}
                                            maxLength={200}
                                            placeholder={i18next.t('instruction_note')}
                                            istextareainput="true"
                                        />
                                        <NJVButton size='large' type='text' shape="round" style={{ marginTop: 30, marginRight: 10 }} onClick={() => this.setState({ openConfirmModal: false, currentStep: 1 })}>{i18next.t("cancel")}</NJVButton>
                                        <NJVButton size='large' type='primary' shape="round" style={{ marginTop: 20 }} onClick={() => this.createOrderWithFormData()}>{i18next.t("create_parcel")}</NJVButton>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === confirmState ?
                                    <>

                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={animationData}
                                            loop={true}
                                            autoplay={true}
                                        />

                                        <div style={{ fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_please_wait")}</div>
                                    </> :
                                    <></>
                            }
                            {
                                orderState === successState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={SuccessAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        {
                                            isOrderTypeUpload ? <>
                                                <div style={{ marginBlock: 10, fontSize: 15, fontWeight: '500' }}>{i18next.t("msg_parcel_create_success")}</div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
                                                    <NJVButton size='large' shape='round' type='text' icon={<PrinterOutlined />} onClick={() => this.airwaybill()}>{i18next.t("msg_print_awb")}</NJVButton>
                                                    <NJVButton size='large' shape='round' type='primary' onClick={() => this.GoToHistory()}>{i18next.t("msg_go_to_parcel_history")}</NJVButton>
                                                </div>
                                            </>
                                                :
                                                <div style={{ marginTop: 30, fontSize: 15, fontWeight: '500' }}>
                                                    သင်၏ပါဆယ်များအား စတင်ဆောင်ရွက်နေပြီဖြစ်ပြီး ခန့်မှန်းကြာမြင့်ချိန် နောက် ၃မိနစ်တွင် ပါဆယ်မှတ်တမ်း(Parcel History)ထဲတွင်၀င်ရောက်စစ်ဆေးနိုင်ပါသည်။
                                                </div>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                            {
                                orderState === failState ?
                                    <>
                                        <Lottie
                                            style={{ height: '200px' }}
                                            animationData={FailAnimation}
                                            loop={false}
                                            autoplay={true}
                                        />
                                        <div style={{ marginTop: 20, marginBottom: 20, fontSize: 15, fontWeight: '500' }}>
                                            {
                                                fail_message_code === '076' ?
                                                    <>
                                                        {i18next.t("msg_fail_to_create_parcel")}
                                                    </>
                                                    :
                                                    fail_message_code === '008' ? <>{i18next.t("msg_reselect_date")}</> :
                                                        <>
                                                            {
                                                                fail_message_code === '077' ?
                                                                    <>
                                                                        {i18next.t("msg_fail_to_create_parcel_077")}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {i18next.t("msg_fail_to_create_parcel")}
                                                                    </>
                                                            }
                                                        </>
                                            }
                                        </div>
                                        <NJVButton size='large' shape='round' type='primary' onClick={() => this.setState({ openConfirmModal: false, orderState: initState })}>
                                            Close
                                        </NJVButton>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </Modal>
                <>
                    {
                        isFetchingUserGroup ?
                            <Row>
                                <Col span={1}></Col>
                                <Col span={22}>
                                    <Skeleton />
                                </Col>
                            </Row>
                            :
                            <>
                                {
                                    isExitUserGroup ?
                                        <Row>
                                            <Col span={24}>
                                                <Row style={{ marginBottom: 30 }}>
                                                    <Col span={1}></Col>
                                                    <Col span={22}>
                                                        <Row>
                                                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                                                                <TitleLevel3 label={i18next.t("parcel_create")} />
                                                                <NJVButton
                                                                    onClick={() => this.setState({ openClearAllDataModal: true })}
                                                                    icon={<SvgBrushOutlined width={14} height={14} color={"#fff"} />}
                                                                    type='primary'
                                                                    style={{ background: Theme.colors.primary }}
                                                                >
                                                                    {i18next.t("clear_all")}
                                                                </NJVButton>
                                                            </Col>
                                                            <Col span={24}>
                                                                {
                                                                    isDataFetching ?
                                                                        <Skeleton active />
                                                                        :
                                                                        <>
                                                                            <Steps current={currentStep} labelPlacement="vertical" items={items} />
                                                                            <br />
                                                                            <Card
                                                                                className='card-shadow'
                                                                                style={{
                                                                                    // backgroundColor: "#f5f5f5",
                                                                                    boxShadow: 'none',
                                                                                    paddingBottom: 20,
                                                                                    marginBottom: 20,
                                                                                    padding: 0
                                                                                }}
                                                                                bordered={false}
                                                                                title={<div style={{ display: 'flex', alignItems: 'center' }}><span>{i18next.t("sender_info")}</span></div>}
                                                                            >
                                                                                <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                                                        <FormLabel label={i18next.t("pickup_address")} />
                                                                                        <NJVSelect
                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                            value={pickupInformation.id}
                                                                                            placeholder={i18next.t("pickup_address")}
                                                                                            onChange={(key, value) => this.handlePickupLocation(value)}
                                                                                            size='large'
                                                                                            style={{ width: '100%' }}
                                                                                            loading={isFetchingMyLocation}
                                                                                            options={
                                                                                                userAddress_collection && userAddress_collection.map((userAddress) => {
                                                                                                    return {
                                                                                                        id: userAddress.id,
                                                                                                        value: userAddress.id,
                                                                                                        label: userAddress.name,
                                                                                                        townshipId: userAddress?.township?.id,
                                                                                                        divisionShortCode: userAddress?.township?.division?.shortCode
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        />

                                                                                    </Col>
                                                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                                                        <FormLabel label={i18next.t("pickup_date")} />
                                                                                        <NJVDatePicker
                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                            name={`pickupDate`}
                                                                                            allowClear={false}
                                                                                            disabledDate={this.disabledDate}
                                                                                            value={pickUpDate}
                                                                                            disabled={isFetchingMyLocation}
                                                                                            placeholder={i18next.t("select_date")}
                                                                                            onChange={(value) => this.handlePickupDate(value)}
                                                                                            size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card>
                                                                            {
                                                                                currentStep === 1 || currentStep === 2 ?
                                                                                    <Row style={{ marginBottom: 30 }}>
                                                                                        <Col span={24}>
                                                                                            <Card
                                                                                                className='card-shadow'
                                                                                                style={{
                                                                                                    // backgroundColor: "#f5f5f5",
                                                                                                    boxShadow: 'none',
                                                                                                }}
                                                                                                bordered={false}
                                                                                                title={<div style={{ display: 'flex', alignItems: 'center' }}><span>{i18next.t("receiver_info")}</span></div>}

                                                                                            >
                                                                                                <Row>
                                                                                                    <Col span={24} style={{ marginBottom: 20 }}>
                                                                                                        <ConfigProvider
                                                                                                            theme={{
                                                                                                                components: {
                                                                                                                    Segmented: {
                                                                                                                        itemColor: '#fff',
                                                                                                                        itemSelectedColor: '#000'
                                                                                                                    },
                                                                                                                },
                                                                                                            }}
                                                                                                        >
                                                                                                            <Segmented
                                                                                                                size='large'
                                                                                                                style={{ backgroundColor: '#c2002f' }}
                                                                                                                block
                                                                                                                options={[
                                                                                                                    {
                                                                                                                        label: <span style={segmentedStyle}>{i18next.t("manual_order_entry")}</span>,
                                                                                                                        value: 'Manual Order Entry'
                                                                                                                    },
                                                                                                                    {
                                                                                                                        label: <span style={segmentedStyle}>{i18next.t("bulk_order_upload")}</span>,
                                                                                                                        value: 'Bulk Order Upload'
                                                                                                                    }
                                                                                                                ]}
                                                                                                                onChange={this.handleSegment}
                                                                                                            />
                                                                                                        </ConfigProvider>
                                                                                                    </Col>
                                                                                                </Row>

                                                                                                {
                                                                                                    isOrderTypeUpload ?
                                                                                                        <>
                                                                                                            <Row>
                                                                                                                <Col lg={24} md={24} sm={0} xs={0}>
                                                                                                                    <Table
                                                                                                                        columns={columns}
                                                                                                                        dataSource={userInput_collection}
                                                                                                                        onChange={this.onChangePagination}
                                                                                                                        rowClassName={(record, index) => {
                                                                                                                            return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                                <Col lg={0} md={0} sm={24} xs={24}>
                                                                                                                    <Table
                                                                                                                        columns={mobile_columns}
                                                                                                                        dataSource={userInput_collection}
                                                                                                                        rowClassName={(record, index) => {
                                                                                                                            return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                            <br />
                                                                                                            <br />
                                                                                                            {/* Order Form */}
                                                                                                            <Form
                                                                                                                ref={this.orderFormRef}
                                                                                                                layout="vertical"
                                                                                                                initialValues={{
                                                                                                                    'orderType': 1,
                                                                                                                    'parcelAmount': 0
                                                                                                                }}
                                                                                                                onFinish={this.handleAddReceiver}
                                                                                                            >
                                                                                                                <Row gutter={[16, 0]}>
                                                                                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                                                                                                        <span style={{ fontSize: 16, fontWeight: '700' }}>{i18next.t("add_new_receiver_info")}</span>
                                                                                                                        <div style={{ display: 'flex' }}>
                                                                                                                            <span style={{ marginRight: 5 }}></span>
                                                                                                                            <Checkbox
                                                                                                                                name='favourite'
                                                                                                                                onChange={(event) => this.handleActivity('favourite', event.target.checked)}
                                                                                                                                checked={form_activity?.favourite}
                                                                                                                            >{i18next.t("select_loyal_customer")}</Checkbox>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    <Col span={24}>
                                                                                                                        <Divider />
                                                                                                                    </Col>
                                                                                                                    <Col span={24} style={{ marginBottom: 30 }}>
                                                                                                                        <Row gutter={[16, 24]}>
                                                                                                                            <Col span={8}>
                                                                                                                                <div
                                                                                                                                    onClick={() => this.handleActivity('orderType', HOME_DELIVERY)}
                                                                                                                                    style={form_activity.orderType === HOME_DELIVERY ? orderTypeCardStyleSelected : orderTypeCardStyle}>
                                                                                                                                    <div className='icon-circle' style={{ marginRight: 10 }}>
                                                                                                                                        <SvgOrderDollyFilled width={20} height={20} color={Theme.colors.primary} />
                                                                                                                                    </div>
                                                                                                                                    <span style={form_activity.orderType === HOME_DELIVERY ? orderTypeTextSeleted : orderTypeText}>{i18next.t('home_delivery')}</span>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            <Col span={8}>
                                                                                                                                <div
                                                                                                                                    onClick={() =>
                                                                                                                                        isBusgateServiceAvailable && this.handleActivity('orderType', BUS_GATE)
                                                                                                                                    }
                                                                                                                                    style={
                                                                                                                                        form_activity.orderType === BUS_GATE ?
                                                                                                                                            orderTypeCardStyleSelected : isBusgateServiceAvailable ? orderTypeCardStyle : orderTypeCardStyleDisable}
                                                                                                                                >
                                                                                                                                    <div className='icon-circle' style={{ marginRight: 10 }}>
                                                                                                                                        <SvgTruckFilled width={20} height={20} color={Theme.colors.primary} />
                                                                                                                                    </div>
                                                                                                                                    <span style={form_activity.orderType === BUS_GATE ? orderTypeTextSeleted : orderTypeText}>{i18next.t('bus_gate')}</span>
                                                                                                                                </div>
                                                                                                                            </Col>
                                                                                                                            {
                                                                                                                                is_postoffice_available ?
                                                                                                                                    <Col span={8}>
                                                                                                                                        <div
                                                                                                                                            onClick={() => this.handleActivity('orderType', POST_OFFICE)}
                                                                                                                                            style={form_activity.orderType === POST_OFFICE ? orderTypeCardStyleSelected : orderTypeCardStyle}>
                                                                                                                                            <div className='icon-circle' style={{ marginRight: 10 }}>
                                                                                                                                                <SvgMailBoxFilled width={20} height={20} color={Theme.colors.primary} />
                                                                                                                                            </div>
                                                                                                                                            <span style={form_activity.orderType === POST_OFFICE ? orderTypeTextSeleted : orderTypeText}>{i18next.t('post_office')}</span>
                                                                                                                                        </div>
                                                                                                                                    </Col>
                                                                                                                                    :
                                                                                                                                    <></>
                                                                                                                            }
                                                                                                                            {/* <Col span={8}>
                                                                                                                                <div
                                                                                                                                    onClick={() => this.handleActivity('orderType', NINJA_POINT)}
                                                                                                                                    style={form_activity.orderType === NINJA_POINT ? orderTypeCardStyleSelected : orderTypeCardStyle}>
                                                                                                                                    <div className='icon-circle' style={{ marginRight: 10 }}>
                                                                                                                                        <SvgStoreFilled width={20} height={20} color={Theme.colors.primary} />
                                                                                                                                    </div>
                                                                                                                                    <span style={form_activity.orderType === NINJA_POINT ? orderTypeTextSeleted : orderTypeText}>{i18next.t('ninja_point')}</span>
                                                                                                                                </div>
                                                                                                                            </Col> */}
                                                                                                                        </Row>
                                                                                                                    </Col>
                                                                                                                    {
                                                                                                                        form_activity && form_activity.favourite ?
                                                                                                                            <>
                                                                                                                                <Col span={24} style={{ marginBottom: 15 }}>
                                                                                                                                    <Form.Item name="favouriteLocationId" label={i18next.t("loyal_customer")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_select_division")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVSelect
                                                                                                                                            style={{
                                                                                                                                                width: '100%',
                                                                                                                                            }}
                                                                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                                                                            placeholder={i18next.t("choose_loyal_customer")}
                                                                                                                                            size="large"
                                                                                                                                            onChange={(key, value) => this.handleActivity('favouriteLocationId', value)}
                                                                                                                                            options={
                                                                                                                                                receiverAddress_collection && receiverAddress_collection.map((userAddress) => {
                                                                                                                                                    return {
                                                                                                                                                        value: userAddress.id,
                                                                                                                                                        label: userAddress.name,
                                                                                                                                                        receiverName: userAddress.name,
                                                                                                                                                        phoneNumber: userAddress.phone,
                                                                                                                                                        address: userAddress.address1,
                                                                                                                                                        townshipId: userAddress?.township?.id,
                                                                                                                                                        townshipName: userAddress?.township?.name,
                                                                                                                                                        townshipPostalCode: userAddress?.township?.postalCode,
                                                                                                                                                        divisionId: userAddress?.township?.division?.id,
                                                                                                                                                        divisionName: userAddress?.township?.division?.name
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                                {
                                                                                                                                    form_activity && form_activity.orderType === HOME_DELIVERY &&
                                                                                                                                    <Col span={24}>
                                                                                                                                        <Form.Item name="sameDay" valuePropName="checked">
                                                                                                                                            <Checkbox
                                                                                                                                                disabled={!isSameDayAvailable}
                                                                                                                                                onChange={(event) => this.handleActivity('sameDay', event.target.checked)}
                                                                                                                                            >
                                                                                                                                                {i18next.t("same_day")}
                                                                                                                                            </Checkbox>
                                                                                                                                        </Form.Item>

                                                                                                                                    </Col>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                                                                                    <Form.Item name="receiverName" label={i18next.t("receiver_name")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_enter_receiver_name")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVInput bgcolor={Theme.colors.light_gray} size="large" onChange={(e) => this.handleActivity('receiverName', e.target.value)} />
                                                                                                                                    </Form.Item>

                                                                                                                                </Col>
                                                                                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                                                                                    <Form.Item name="phoneNumber" label={i18next.t("receiver_phone_number")}
                                                                                                                                        rules={[
                                                                                                                                            { validator: PhoneNumberValidator },
                                                                                                                                            { required: true, message: `${i18next.t("err_msg_invalid_phone_number")}` },]}
                                                                                                                                    >
                                                                                                                                        <NJVInput bgcolor={Theme.colors.light_gray} size="large" placeholder="09" onChange={(e) => this.handleActivity('receiverPhone', e.target.value)} />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                                                                                    <Form.Item name="divisionId" label={i18next.t("division_state")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_select_division")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVSelect
                                                                                                                                            style={{
                                                                                                                                                width: '100%',
                                                                                                                                            }}
                                                                                                                                            showSearch
                                                                                                                                            loading={isFetchingDivision}
                                                                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                                                                            placeholder={i18next.t("division_state")}
                                                                                                                                            size="large"
                                                                                                                                            filterOption={this.onTownshipFilterOption}
                                                                                                                                            onChange={(key, value) => this.handleActivity('divisionId', value)}
                                                                                                                                            options={
                                                                                                                                                divisionList && divisionList.map((division) => {
                                                                                                                                                    return {
                                                                                                                                                        value: division.id,
                                                                                                                                                        label: division.name,
                                                                                                                                                        type: 'divisionId'
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                                <Col lg={6} md={6} sm={12} xs={12}>
                                                                                                                                    <Form.Item name="townshipId" label={i18next.t("township_city")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_select_township")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVSelect
                                                                                                                                            showSearch
                                                                                                                                            style={{
                                                                                                                                                width: '100%',
                                                                                                                                            }}
                                                                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                                                                            placeholder={i18next.t("township_city")}
                                                                                                                                            size="large"
                                                                                                                                            onChange={(key, value) => this.handleActivity('townshipId', value)}
                                                                                                                                            filterOption={this.onTownshipFilterOption}
                                                                                                                                            options={
                                                                                                                                                townshipList && townshipList.map((township) => {
                                                                                                                                                    return {
                                                                                                                                                        value: township.id,
                                                                                                                                                        label: township.name,
                                                                                                                                                        type: 'townshipId',
                                                                                                                                                        postalCode: township.postalCode
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                                {
                                                                                                                                    form_activity && (form_activity.orderType === HOME_DELIVERY || form_activity.orderType === POST_OFFICE) &&
                                                                                                                                    <Col span={24}>
                                                                                                                                        <Form.Item name="address" label={i18next.t("address")}
                                                                                                                                            rules={[
                                                                                                                                                {
                                                                                                                                                    required: true,
                                                                                                                                                    message: `${i18next.t("err_msg_enter_address")}`
                                                                                                                                                }
                                                                                                                                            ]}
                                                                                                                                        >
                                                                                                                                            <NJVInput
                                                                                                                                                bgcolor={Theme.colors.light_gray}
                                                                                                                                                size="large"
                                                                                                                                                showCount
                                                                                                                                                onChange={(e) => this.handleActivity('receiverAddress', e.target.value)}
                                                                                                                                                style={{
                                                                                                                                                    width: '100%',
                                                                                                                                                }}
                                                                                                                                                maxLength={180}
                                                                                                                                                istextareainput="true"
                                                                                                                                            />
                                                                                                                                        </Form.Item>
                                                                                                                                    </Col>
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    form_activity && form_activity.orderType === HOME_DELIVERY &&
                                                                                                                                    <Col span={24}>
                                                                                                                                        <Form.Item name="sameDay" valuePropName="checked">
                                                                                                                                            <Checkbox
                                                                                                                                                disabled={!isSameDayAvailable}
                                                                                                                                                onChange={(event) => this.handleActivity('sameDay', event.target.checked)}
                                                                                                                                            >
                                                                                                                                                {i18next.t("same_day")}
                                                                                                                                            </Checkbox>
                                                                                                                                        </Form.Item>
                                                                                                                                    </Col>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        form_activity.orderType === 2 ?
                                                                                                                            <>
                                                                                                                                <Col lg={12} md={12} xs={24} sm={24}>
                                                                                                                                    <Form.Item name="busGateId" label={i18next.t("bus_gate_name")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_select_bus_gate")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVSelect
                                                                                                                                            style={{
                                                                                                                                                width: '100%',
                                                                                                                                            }}
                                                                                                                                            loading={isFetchingBusGate}
                                                                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                                                                            placeholder={i18next.t("bus_gate_name")}
                                                                                                                                            size="large"
                                                                                                                                            onChange={(key, value) => this.handleActivity('busGateId', value)}
                                                                                                                                            options={
                                                                                                                                                busGateList && busGateList.map((busGate) => {
                                                                                                                                                    return {
                                                                                                                                                        value: busGate.id,
                                                                                                                                                        label: busGate.busGateName,
                                                                                                                                                        type: 'busGateId'
                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <>
                                                                                                                            </>
                                                                                                                    }
                                                                                                                    {
                                                                                                                        form_activity.orderType === 3 ?
                                                                                                                            <>
                                                                                                                                <Col lg={12} md={12} xs={24} sm={24}>
                                                                                                                                    <Form.Item name="partnershipStoreId" label={i18next.t("partner_store")}
                                                                                                                                        rules={[
                                                                                                                                            {
                                                                                                                                                required: true,
                                                                                                                                                message: `${i18next.t("err_msg_select_partner_store")}`
                                                                                                                                            }
                                                                                                                                        ]}
                                                                                                                                    >
                                                                                                                                        <NJVSelect
                                                                                                                                            style={{
                                                                                                                                                width: '100%',
                                                                                                                                            }}
                                                                                                                                            loading={isFetchingPartnershipStore}
                                                                                                                                            bgcolor={Theme.colors.light_gray}
                                                                                                                                            placeholder={i18next.t("partner_store")}
                                                                                                                                            size="large"
                                                                                                                                            onChange={(key, value) => this.handleActivity('partnershipStoreId', value)}
                                                                                                                                            options={
                                                                                                                                                partnershipStoreList && partnershipStoreList.map((partnershipStore) => {
                                                                                                                                                    return {
                                                                                                                                                        value: partnershipStore.id,
                                                                                                                                                        label: partnershipStore.fullName,
                                                                                                                                                        address: partnershipStore.address,
                                                                                                                                                        type: 'partnershipStoreId'

                                                                                                                                                    }
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        />
                                                                                                                                    </Form.Item>
                                                                                                                                </Col>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }
                                                                                                                    {/* {
                                                                                                                        form_activity.orderType === 1 || form_activity.orderType === 3 ? */}
                                                                                                                    <Col lg={6} md={6} xs={12} sm={12} >
                                                                                                                        <Form.Item name="parcelAmount" label={i18next.t("parcel_value")}
                                                                                                                            rules={[
                                                                                                                                { validator: ParcelAmountValidator }
                                                                                                                            ]}
                                                                                                                        >
                                                                                                                            <NJVInput
                                                                                                                                bgcolor={Theme.colors.light_gray}
                                                                                                                                type='text'
                                                                                                                                name="parcelAmount"
                                                                                                                                size="large"
                                                                                                                                onChange={e => this.changeParcelAmount(e.target.value)}
                                                                                                                            />
                                                                                                                        </Form.Item>
                                                                                                                    </Col>
                                                                                                                    {/* :
                                                                                                                            <></>
                                                                                                                    } */}
                                                                                                                    <Col lg={6} md={6} xs={12} sm={12}>
                                                                                                                        <Form.Item name="deliveryFees" label={i18next.t("delivery_fees")}>
                                                                                                                            <NJVInput
                                                                                                                                disabled
                                                                                                                                type='text' name="parcelAmount" size="large"
                                                                                                                                suffix={<>
                                                                                                                                    {
                                                                                                                                        isFetchingPriceScript ?
                                                                                                                                            <Spin
                                                                                                                                                indicator={
                                                                                                                                                    <LoadingOutlined
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 18,
                                                                                                                                                            color: Theme.colors.primary
                                                                                                                                                        }}
                                                                                                                                                        spin
                                                                                                                                                    />
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                            : <></>
                                                                                                                                    }
                                                                                                                                </>} />
                                                                                                                        </Form.Item>
                                                                                                                    </Col>
                                                                                                                    <Col lg={12} md={12} xs={24} sm={24} >
                                                                                                                        <Form.Item name="paymentOption" label={i18next.t("payment_option")}
                                                                                                                            rules={[
                                                                                                                                {
                                                                                                                                    required: true,
                                                                                                                                    message: `${i18next.t("err_msg_select_payment_option")}`
                                                                                                                                }
                                                                                                                            ]}
                                                                                                                        >
                                                                                                                            <Radio.Group>
                                                                                                                                <Radio
                                                                                                                                    value={1}
                                                                                                                                    disabled={(form_activity.orderType === BUS_GATE || form_activity.orderType === POST_OFFICE) ? true : false}
                                                                                                                                    onChange={() => this.handleActivity('paymentOption', 1)}
                                                                                                                                >
                                                                                                                                    {i18next.t("collect_from_customer")}
                                                                                                                                </Radio>
                                                                                                                                <Radio
                                                                                                                                    value={2}
                                                                                                                                    onChange={() => this.handleActivity('paymentOption', 2)}
                                                                                                                                >
                                                                                                                                    {i18next.t("pay_by_shipper")}
                                                                                                                                </Radio>
                                                                                                                            </Radio.Group>
                                                                                                                        </Form.Item>
                                                                                                                    </Col>
                                                                                                                    {
                                                                                                                        form_activity.orderType === 2 ?
                                                                                                                            <Col span={24} >
                                                                                                                                <Form.Item name="busGateFees" valuePropName='checked'>
                                                                                                                                    <Checkbox>{i18next.t("pay_bus_gate_fees")}</Checkbox>
                                                                                                                                </Form.Item>
                                                                                                                            </Col>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }

                                                                                                                    <Col span={24}>
                                                                                                                        <Form.Item name="note" label={i18next.t("note")}>
                                                                                                                            <NJVInput
                                                                                                                                bgcolor={Theme.colors.light_gray}
                                                                                                                                name="note"
                                                                                                                                showCount
                                                                                                                                maxLength={100}
                                                                                                                                style={{
                                                                                                                                    width: '100%',
                                                                                                                                    height: 80
                                                                                                                                }}
                                                                                                                                placeholder={i18next.t("note_text")}
                                                                                                                                istextareainput="true"
                                                                                                                            />
                                                                                                                        </Form.Item>
                                                                                                                    </Col>
                                                                                                                    {
                                                                                                                        form_activity.orderType === 1 && (
                                                                                                                            <Col span={24} style={{ marginBottom: 10 }}>
                                                                                                                                <InsuranceForm
                                                                                                                                    senderData={senderInfo} receiverData={receiverInfo}
                                                                                                                                    handleReceiverData={this.handleReceiverData}

                                                                                                                                    insuranceData={this.state.insuranceInfo}
                                                                                                                                    handleInsuranceData={this.handleInsuranceData}

                                                                                                                                    removeStatus={confirmRemoveInsuranceData}
                                                                                                                                    handleRemoveStatus={this.handleRemoveStatus}

                                                                                                                                    isInsurance={is_insurance_checked}
                                                                                                                                    handleInsuranceCheckbox={this.handleInsuranceCheckbox}
                                                                                                                                />
                                                                                                                            </Col>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        currentStep === 0 || currentStep === 1 ?
                                                                                                                            <>
                                                                                                                                <Col span={12}>
                                                                                                                                    <div style={{ display: 'flex', height: 31 }}>
                                                                                                                                        <NJVButton type="primary" onClick={() => this.clearInput()} danger style={{ marginRight: 10 }}>{i18next.t("clear")}</NJVButton>
                                                                                                                                        <Form.Item>
                                                                                                                                            <NJVButton type="primary" htmlType="submit">
                                                                                                                                                {
                                                                                                                                                    isUpdateData ? <span>Update</span> : <span>{i18next.t("add_receiver")}</span>
                                                                                                                                                }
                                                                                                                                            </NJVButton>
                                                                                                                                        </Form.Item>
                                                                                                                                    </div>
                                                                                                                                </Col>
                                                                                                                                <Col span={12} style={{ textAlign: 'right' }}>
                                                                                                                                    {
                                                                                                                                        userInput_collection && userInput_collection.length !== 0 ?
                                                                                                                                            <div style={{ height: 31 }}>
                                                                                                                                                <NJVButton type="primary" onClick={() => this.setState({ currentStep: 2 })} style={{ marginRight: 10 }}>{i18next.t("finish")}</NJVButton>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }

                                                                                                                                </Col>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            <></>
                                                                                                                    }


                                                                                                                </Row>
                                                                                                            </Form>
                                                                                                            {/* Order From */}
                                                                                                        </>
                                                                                                        :
                                                                                                        <Row>
                                                                                                            {
                                                                                                                excel_order_list && excel_order_list.length !== 0 ?
                                                                                                                    <Col lg={24} md={24} sm={0} xs={0}>
                                                                                                                        <div style={{ marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
                                                                                                                            <div>
                                                                                                                                <span className='valid-tag'>Valid : {valid_excel_role}</span>
                                                                                                                                {
                                                                                                                                    invalid_excel_role && invalid_excel_role !== 0 ?
                                                                                                                                        <span className='invalid-tag'>Invalid : {invalid_excel_role}</span>
                                                                                                                                        :
                                                                                                                                        <></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            <NJVButton type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.uploadNewExcel()}>{i18next.t("upload_new_excel")}</NJVButton>
                                                                                                                        </div>
                                                                                                                        <Table
                                                                                                                            columns={excel_order_columns}
                                                                                                                            dataSource={excel_order_list}
                                                                                                                            pagination={{
                                                                                                                                position: 'top' | 'bottom',
                                                                                                                                total: excel_order_list.length,
                                                                                                                                current: excel_page,
                                                                                                                                onChange: this.onChangeExcelPagination,
                                                                                                                                defaultPageSize: 50,
                                                                                                                                showSizeChanger: false
                                                                                                                            }}
                                                                                                                            rowClassName={(record, index) => {
                                                                                                                                return index % 2 === 0 ? 'blue-row' : 'black-row';
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <div>
                                                                                                                            <NJVButton disabled={invalid_excel_role === 0 && valid_excel_role > 0 ? false : true} type="primary" size='large' onClick={() => this.confirm()}>{i18next.t("create_parcel")}</NJVButton>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                    :
                                                                                                                    <Col span={24}>
                                                                                                                        <Col lg={24} md={24} sm={0} xs={0} style={{ textAlign: 'center' }}>
                                                                                                                            <NJVButton type="primary" shape="round" icon={<DownloadOutlined />} size='large' loading={downloadingExcel} onClick={() => this.downloadExampleTemplate()}>
                                                                                                                                {i18next.t("download_template_sample")}
                                                                                                                            </NJVButton>
                                                                                                                            <Dragger
                                                                                                                                {...draggerProps}
                                                                                                                                accept={[".xlsx", ".xls"]}
                                                                                                                                maxCount={1}
                                                                                                                                style={{ marginTop: 15 }}>
                                                                                                                                <p className="ant-upload-drag-icon">
                                                                                                                                    <InboxOutlined />
                                                                                                                                </p>
                                                                                                                                <p className="ant-upload-hint">
                                                                                                                                    {
                                                                                                                                        isExcelFileUploading ?
                                                                                                                                            <div>
                                                                                                                                                <Spin /> <span>uploading...</span>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <span>{i18next.t("upload_excel_file_only")}</span>
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            </Dragger>

                                                                                                                        </Col>
                                                                                                                    </Col>
                                                                                                            }
                                                                                                        </Row>
                                                                                                }
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {
                                                                                currentStep === 2 && isOrderTypeUpload ?
                                                                                    <Row style={{ marginBottom: 30 }}>
                                                                                        <Col span={24}>
                                                                                            <Card
                                                                                                className='card-shadow'
                                                                                                style={{
                                                                                                    boxShadow: 'none',
                                                                                                }}
                                                                                                bordered={false}
                                                                                                title={<div style={{ display: 'flex', alignItems: 'center' }}><span>{i18next.t("create_parcel")}</span></div>}
                                                                                                extra={<NJVButton type="text" size='middle' onClick={() => this.setState({ currentStep: 1 })} style={{ display: 'flex', alignItems: 'center' }}><SvgPlusFilled width={20} height={20} color={Theme.colors.system_blue} /><span style={{ marginLeft: 5 }}>{i18next.t("add_more_parcel")}</span></NJVButton>}
                                                                                            >
                                                                                                <Row>
                                                                                                    <Col span={12}>
                                                                                                        {
                                                                                                            userInput_collection?.length === 1 ?
                                                                                                                <span>You added a total of {userInput_collection?.length} parcel to the list</span>
                                                                                                                :
                                                                                                                <span>You added a total of {userInput_collection?.length} parcels to the list</span>
                                                                                                        }
                                                                                                    </Col>


                                                                                                    {
                                                                                                        userInput_collection && userInput_collection.length !== 0 && isOrderTypeUpload ?
                                                                                                            <Col span={12} style={{ textAlign: 'right' }}>
                                                                                                                <NJVButton type="primary" size='large' onClick={() => this.confirm()}>{i18next.t("create_parcel_now")}</NJVButton>
                                                                                                            </Col>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </Row>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col >
                                                </Row >
                                            </Col >
                                        </Row >
                                        :
                                        <Row style={{ marginTop: 100, justifyContent: 'center', width: '100%' }}>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Lottie
                                                    style={{ height: '200px' }}
                                                    animationData={NoParcelCreate}
                                                    loop={false}
                                                    autoplay={true}
                                                />
                                            </Col>
                                            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                <div style={{ fontSize: 17 }}>
                                                    သင့်အကောင့်သည် ပါဆယ်ပြုလုပ်ရန်အဆင်သင့်မဖြစ်သေးပါ။
                                                    <br />
                                                    Ninja Vanသို့ဆက်သွယ်ပါ။
                                                </div>
                                            </Col>
                                        </Row>
                                }
                            </>
                    }
                </>
            </>
        )
    }
}

export default compose(withRouter)(NewAdvanceOrderForm)