import { CheckCircleFilled } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Col, Row, Statistic, message } from "antd";
import i18next from "i18next";
import React from "react";
import CountUp from 'react-countup';
import { NJVButton, NJVCard, NJVDatePicker, NJVInput } from "../components/core-component";
import Theme from "../components/theme";
import '../css/dashboard.css';
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler } from "../network/network-manager";
import withRouter from "../network/with-router";
import { updateSelectedMenu } from "../redux/cookie-slice";
import { updateProfile } from "../redux/profile-slice";
import CustomPath from "../routes/custom-path";

const formatter = (value) => <CountUp end={value} separator="," />;

const titlfontStyle = {
    fontWeight: '600',
    fontSize: 16
}

const data = [
    ["2023", ""],
    ["January", 50],
    ["February", 250],
    ["March", 300],
    ["April", 350],
    ["May", 500],
    ["June", 700],
    ["July", 200],
    ["August", 800],
    ["September", 340],
];

const monthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"

}

const options = {
    chart: {
        title: "Company Performance",
        subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
};

class DashboardPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // completed_orders: 0,
            // pending_orders: 0,
            // rts_orders: 0,
            // in_progress_orders: 0,
            // order_completed_chart: [],
            // is_zero_record: true,
            filter_name: '',
            // top_city: [],
            // top_customer: [],
            // top_city_exist: false,
            // top_customer_exist: false
            totalCod: 0,
            totalParcel: 0,
            totalOngoingParcel: 0,
            totalCompleteParcel: 0,
            totalReturnParcel: 0,
            filterData: {},
            isSearchingData: false
        }
    }

    componentDidMount() {
        this.fetchProfile()
        // this.fetchDashBoard()
        this.props.dispatch(updateSelectedMenu("0"))
        // this.fetchAnalyticDashboard()
        this.fetchReport();
    }
    fetchProfile = async () => {
        try {
            const response = await ApiHandler({ url: Api.shipper_profile, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.props.dispatch(updateProfile(response))
            }
        } catch (error) {

        }
    }
    // fetchDashBoard = async () => {
    //     try {
    //         const response = await ApiHandler(Api.shipper_dashboard, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
    //         if (response && response.status === 200) {
    //             if (response.data) {
    //                 let monthList = []
    //                 let list1 = []
    //                 list1.push("2023")
    //                 list1.push("Order")
    //                 monthList.push(list1)
    //                 let isZeroRecord = true
    //                 response.data.completed_oder_by_months && response.data.completed_oder_by_months.map(obj => {
    //                     let month = monthMap[obj.month]
    //                     let list = []
    //                     list.push(month)
    //                     list.push(1)
    //                     monthList.push(list)
    //                     if (obj.count !== 0) {
    //                         isZeroRecord = false
    //                     }
    //                 })
    //                 this.setState({
    //                     order_pending: response.data.pending_order,
    //                     order_completed_chart: monthList,
    //                     in_progress_orders: response.in_progress_orders,
    //                     pending_orders: response.pending_pick_up_orders,
    //                     completed_orders: response.completed_orders,
    //                     rts_orders: response.rts_orders,
    //                     is_zero_record: isZeroRecord
    //                 })
    //             }

    //         }
    //     } catch (error) {

    //     }
    // }

    handleFilter = (event) => {
        this.setState({ filter_name: event.target.value })
    }

    search = async () => {
        const { filter_name } = this.state
        if (!filter_name || filter_name.length < 15) {
            message.error("Please enter tracking number first.")
            return
        }
        this.props.navigate(`${CustomPath.single_order_result}/${filter_name}`)
    }

    // fetchAnalyticDashboard = async () => {
    //     try {
    //         const response = await ApiHandler(Api.shipper_analytic_dashboard, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
    //         if (response) {

    //             let townshipList = []
    //             townshipList.push(["Township", "Total Order"])
    //             response.top_city && response.top_city.map(city => {
    //                 let list = []
    //                 list.push(city.name)
    //                 list.push(city.total)
    //                 townshipList.push(list)
    //             })

    //             let customerList = []
    //             customerList.push(["Customer", "Total Order"])
    //             response.top_customer && response.top_customer.map(city => {
    //                 let list = []
    //                 list.push(city.name)
    //                 list.push(city.total)
    //                 customerList.push(list)
    //             })
    //             this.setState({
    //                 top_city: townshipList,
    //                 top_customer: customerList,
    //                 top_city_exist: townshipList ? true : false,
    //                 top_customer_exist: customerList ? true : false,
    //                 in_progress_orders: response.in_progress_orders,
    //                 pending_orders: response.pending_pick_up_orders,
    //                 completed_orders: response.completed_orders,
    //                 rts_orders: response.rts_orders,
    //             })
    //         }
    //     } catch (error) {
    //     }
    // }

    fetchReport = async () => {
        const { filterData } = this.state
        try {
            let requestParams = {}
            const { fromDate, toDate } = filterData;
            if (filterData) {
                if ((fromDate && !toDate) || (!fromDate && toDate) || (fromDate > toDate)) {
                    message.error("Invalid date")
                    return
                }
                requestParams = {
                    ...filterData,
                    fromDate: fromDate ? fromDate.format("YYYY-MM-DD") : undefined,
                    toDate: toDate ? toDate.format("YYYY-MM-DD") : undefined
                }
            }
            const response = await ApiHandler({ url: Api.shipper_report, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams });
            if (response) {
                const { totalCod, totalParcel, totalOngoingParcel, totalCompleteParcel, totalReturnParcel } = response
                this.setState({
                    totalCod,
                    totalParcel,
                    totalOngoingParcel,
                    totalCompleteParcel,
                    totalReturnParcel,
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                isSearchingData: false
            })
        }
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state;
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData
        })
    }

    search = () => {
        this.setState({
            isSearchingData: true
        }, () => this.fetchReport())
    }


    render() {
        const { totalCod, totalParcel, totalOngoingParcel, totalCompleteParcel, totalReturnParcel, isSearchingData } = this.state
        return (
            <Row>
                <Col span={1} />
                <Col span={22}>
                    {/* <Row style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <Marquee
                                style={{ fontSize: 17, color: 'red', fontWeight: '500' }}
                            >
                                <div>လက်တလောတွင် ပဲခူးမြစ်ရေလျှံကာေဇာင်းတူဆည်ရေကျော်မှုကြောင့် ရေကြီးရေလျှံဖြစ်နေသောပဲခူး၊ သနပ်ပင်၊ အင်းတကော်မြို့များ သို့ ပစ္စည်းပို့ဆောင််မှုကို ခေတ္တရပ်နားထားပါတယ်။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            </Marquee>
                        </Col>
                    </Row> */}
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{}}>
                            <Row>
                                <Col lg={20} md={20} sm={18} xs={18}>
                                    <NJVInput
                                        size='large'
                                        style={{ backgroundColor: '#f5f5f5', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderTopRightRadius: 0, borderBottomRightRadius: 0 }} placeholder={i18next.t('enter_tracking_id')} onChange={this.handleFilter} />
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <NJVButton size="large" type="primary" shape='square' style={{ backgroundColor: Theme.colors.primary, width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10 }} onClick={() => this.search()}>{i18next.t("search")}</NJVButton>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <NJVCard cardBgColor="white" className="card-shadow" style={{ paddingTop: 15, paddingBottom: 15 }}>
                                <Row gutter={[16, 16]}>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <NJVDatePicker bgcolor={Theme.colors.light_gray} size="large" onChange={(value) => this.updateFilterData("fromDate", value)}
                                            style={{ width: '100%' }} placeholder={i18next.t("from_date")} />
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <NJVDatePicker bgcolor={Theme.colors.light_gray} size="large" onChange={(value) => this.updateFilterData("toDate", value)}
                                            style={{ width: '100%' }} placeholder={i18next.t("to_date")} />
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={12}>
                                        <NJVButton loading={isSearchingData} size="large" type="primary" style={{ width: '100%' }} onClick={() => this.search()}>{i18next.t("search")}</NJVButton>
                                    </Col>
                                </Row>
                            </NJVCard>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12}>
                            <NJVCard
                                cardBgColor="white"
                                className="card-shadow">
                                <Statistic
                                    title={<span style={titlfontStyle}><CheckCircleFilled style={{ fontSize: 17, color: '#16a34a', marginRight: 10 }} />{i18next.t("total_cod")}</span>}
                                    value={totalCod}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12}>
                            <NJVCard
                                cardBgColor="white"
                                className="card-shadow">
                                <Statistic
                                    title={<span style={titlfontStyle}><CheckCircleFilled style={{ fontSize: 17, color: '#1f2937', marginRight: 10 }} />{i18next.t("total_parcel")}</span>}
                                    value={totalParcel}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12}>
                            <NJVCard
                                cardBgColor="white"
                                className="card-shadow"
                            >
                                <Statistic
                                    title={<span style={titlfontStyle}><CheckCircleFilled style={{ fontSize: 17, color: '#3b82f6', marginRight: 10 }} />{i18next.t("inProgress_parcel")}</span>}
                                    value={totalOngoingParcel}
                                    valueStyle={{
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12}>
                            <NJVCard
                                cardBgColor="white"
                                className="card-shadow">
                                <Statistic
                                    title={<span style={titlfontStyle}><CheckCircleFilled style={{ fontSize: 17, color: '#16a34a', marginRight: 10 }} />{i18next.t("complete_parcel")}</span>}
                                    value={totalCompleteParcel}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12}>
                            <NJVCard
                                cardBgColor="white"
                                className="card-shadow">
                                <Statistic
                                    title={<span style={titlfontStyle}><CheckCircleFilled style={{ fontSize: 17, color: '#e11d48', marginRight: 10 }} />{i18next.t("return_parcel")}</span>}
                                    value={totalReturnParcel}
                                    valueStyle={{
                                        color: '#3f8600',
                                    }}
                                    formatter={formatter}
                                />
                            </NJVCard>
                        </Col>

                    </Row>

                    <Row style={{ marginTop: 50 }} gutter={[16, 16]}>
                        {/* {
                            !is_zero_record ?
                                <Col span={24}>
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="400px"
                                        data={order_completed_chart}
                                        options={{
                                            chart: {
                                                title: "Completed Order Chart",
                                            },
                                        }}
                                    />
                                </Col>
                                :
                                <></>
                        } */}
                        {/* 
                        {
                            top_customer_exist ?
                                <Col span={24}>
                                    <Chart
                                        chartType="Bar"
                                        colo
                                        width="100%"
                                        height="400px"
                                        data={top_customer}
                                        options={{
                                            chart: {
                                                title: "Top Customer",
                                            },
                                            colors: [Colors.primary]
                                        }}
                                    />
                                </Col>
                                :
                                <></>
                        }

                        {
                            top_city_exist ?
                                <Col span={24}>
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="400px"
                                        data={top_city}
                                        options={{
                                            chart: {
                                                title: "Top City",
                                            },
                                            colors: [Colors.primary]
                                        }}
                                    />
                                </Col>
                                :
                                <></>
                        } */}


                    </Row>


                </Col>
            </Row>

        )
    }
}

export default compose(withRouter)(DashboardPage)