import { Col, Collapse, Flex, Image, Row, Skeleton } from 'antd'
import React, { Component } from 'react'
import { SvgChevronDown, SvgChevronRight } from '../../components/custom-svgIcon'
import { BackTitleCompo } from '../../components/general-component'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import CustomPath from '../../routes/custom-path'
import NoData from '../../asset/no-data-found.png'

export default class LoyaltyFaq extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isDataFetching: false,
        }
    }

    componentDidMount() {
        this.fetchFaqLoyaltyData()
    }

    fetchFaqLoyaltyData = async () => {
        const params = {
            "targetType": "LOYALTY"
        }
        this.setState({ isDataFetching: true })
        await ApiHandler({ url: Api.faq, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
            .then(response => {
                this.setState({ isDataFetching: false, data: response })
            }).catch(error => { })
            .finally(() => {
                this.setState({ isDataFetching: false })
            })
    }
    render() {
        const { isDataFetching, data } = this.state

        const itemsNest = data?.map(faq => faq?.faqs?.length > 0 && faq?.faqs?.map(faqs => ({
            key: faqs.id,
            label: faqs.question,
            children: faqs.answer,
        })))

        const items = data?.map((faqData, index) => ({
            key: faqData.id,
            label: faqData.title,
            children: <Collapse defaultActiveKey="1" items={itemsNest[index]} />,
        }));


        return (
            <Row>
                <Col lg={6} md={5} sm={1} xs={1} />
                <Col lg={12} md={14} sm={22} xs={22}>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <BackTitleCompo label={'Ninja Rewards FAQ'} onClickRoute={CustomPath.point} />
                        </Col>
                    </Row>
                    <Col span={24}>
                        {
                            isDataFetching ? <Skeleton /> :
                                data?.length === 0 ? <Flex justify='center' align='center' style={{ marginTop: 80 }}>
                                    <Image src={NoData} alt='no faq' width={120} preview={false} />
                                </Flex> :
                                    <Collapse items={items}
                                        expandIconPosition="end"
                                        bordered={false}
                                        expandIcon={({ isActive }) => <>
                                            {isActive ? <SvgChevronDown width={20} height={20} color={'black'} /> : <SvgChevronRight width={20} height={20} color={'black'} />}</>}
                                    />
                        }
                    </Col>
                </Col>
            </Row>
        )
    }
}
