import {
    RightCircleFilled
} from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, ConfigProvider, Divider, Flex, Image, Progress, Row, Skeleton, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PlatinumAward from '../../asset/award_grey.png'
import ShipperAward from '../../asset/award_red.png'
import GoldAward from '../../asset/award_yellow.png'
import { NJVCard } from '../../components/core-component'
import { BackTitleCompo } from '../../components/general-component'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import HowItWork from './how-it-work'

const awardMap = {
    'Shipper': ShipperAward,
    'Gold': GoldAward,
    'Platinum': PlatinumAward,
}

class PointPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loyaltyInfo: null,
            isFetchingData: false,
        }
    }

    componentDidMount() {
        this.fetchLoyaltyInfo()
    }

    fetchLoyaltyInfo = async () => {
        this.setState({
            isFetchingData: true
        })
        await ApiHandler({ url: Api.loyalty_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    loyaltyInfo: response
                })
            })
            .catch((error) => {
                // handle error
            }).finally(() => {
                this.setState({
                    isFetchingData: false
                })
            })
    }
    render() {
        const { loyaltyInfo, isFetchingData } = this.state

        return (
            <Row>
                <Col lg={6} md={5} sm={1} xs={1} />
                <Col lg={12} md={14} sm={22} xs={22}>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={24}>
                            <BackTitleCompo label={'Point Information'} onClickRoute={CustomPath.loyalty} />
                        </Col>
                    </Row>
                    <NJVCard cardBgColor={'#ff5e66'}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <NJVCard>
                                    {
                                        isFetchingData ? <Skeleton /> : <Flex vertical gap={20}>
                                            <div>
                                                <Flex align='center' gap={10}>
                                                    <Typography.Title level={4} style={{ margin: 0 }}>Member - <span style={{ fontWeight: 'bold', fontSize: 25, fontStyle: 'italic' }}>{loyaltyInfo?.currentMonthLevel}</span></Typography.Title>
                                                    <div style={{ background: '#fff', borderRadius: '50%', width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Image src={awardMap[loyaltyInfo?.currentMonthLevel]} preview={false} width={30} height={30} />
                                                    </div>
                                                </Flex>
                                                <Typography.Title level={5} style={{ margin: 0, color: Theme.colors.primary, fontWeight: 'bold' }}>ID No - {loyaltyInfo?.uniqueId}</Typography.Title>
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <ConfigProvider theme={{
                                                    components: {
                                                        Progress: {
                                                            defaultColor: Theme.colors.primary,
                                                            // remainingColor: 'linear-gradient(to left, #c2002f, #ff5e66)'
                                                            // remainingColor: '#c2002f'
                                                        }
                                                    }
                                                }}>
                                                    <Progress percent={Math.round(loyaltyInfo?.nextLevelPercent)} percentPosition={{
                                                        align: 'end',
                                                        type: 'inner',
                                                    }} size={['100%',15]} />
                                                </ConfigProvider>
                                                <img src={awardMap[loyaltyInfo?.nextMonthLevel]} width={50} height={50} style={{ position: 'absolute', right: -8, bottom: 17 }} />
                                                <Flex justify='space-between' style={{marginTop: 10}}>
                                                    <Typography.Text style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{`${loyaltyInfo?.nextMonthLevel === 'Platinum' ? '' : 'Progress to '}`}{loyaltyInfo?.nextMonthLevel}</Typography.Text>
                                                    <Typography.Text>Valid till {dayjs(loyaltyInfo?.validDate).format('YYYY-MM-DD')}</Typography.Text>
                                                </Flex>
                                            </div>
                                        </Flex>
                                    }
                                </NJVCard>
                                <ConfigProvider theme={{
                                    components: {
                                        Divider: {
                                            orientationMargin: 4
                                        }
                                    }
                                }}>

                                    <Divider variant='dotted' style={{ margin: 0}} />
                                </ConfigProvider>
                                <NJVCard>
                                    <Link to={CustomPath.point_history}>
                                        <Flex gap={10} justify='right' align='center'>
                                            <Typography.Title level={5} style={{ margin: 0, color: Theme.colors.primary }}>Point History</Typography.Title>
                                            <RightCircleFilled style={{ fontSize: 20, color: Theme.colors.primary, fontSize: 30, marginRight: 10 }} />
                                        </Flex>
                                    </Link>
                                </NJVCard>
                            </Col>
                            <Col span={24}>
                                <NJVCard cardborder={true} style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30, }}>
                                    <Typography.Title level={4} style={{ margin: 0, marginBottom: 20 }}>Target Points</Typography.Title>
                                    <Row gutter={[16, 24]}>
                                        <Col span={8}>
                                            <NJVCard cardBgColor={Theme.colors.primary}>
                                                <Flex vertical align='center' justify='center' gap={20}>
                                                    <div style={{ background: '#fff', borderRadius: '50%', width: 80, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Image src={ShipperAward} preview={false} width={50} height={50} />
                                                    </div>
                                                    <Flex vertical align='center'>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>Shipper</Typography.Text>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>1 - 9</Typography.Text>
                                                    </Flex>
                                                </Flex>
                                            </NJVCard>
                                        </Col>
                                        <Col span={8}>
                                            <NJVCard cardBgColor={Theme.colors.primary}>
                                                <Flex vertical align='center' justify='center' gap={20}>
                                                    <div style={{ background: '#fff', borderRadius: '50%', width: 80, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Image src={GoldAward} preview={false} width={50} height={50} />
                                                    </div>
                                                    <Flex vertical align='center'>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>Gold</Typography.Text>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>10 - 199</Typography.Text>
                                                    </Flex>
                                                </Flex>
                                            </NJVCard>
                                        </Col>
                                        <Col span={8}>
                                            <NJVCard cardBgColor={Theme.colors.primary}>
                                                <Flex vertical align='center' justify='center' gap={20}>
                                                    <div style={{ background: '#fff', borderRadius: '50%', width: 80, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Image src={PlatinumAward} preview={false} width={50} height={50} />
                                                    </div>
                                                    <Flex vertical align='center'>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>Platinum</Typography.Text>
                                                        <Typography.Text style={{ color: '#fff', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>200 and above</Typography.Text>
                                                    </Flex>
                                                </Flex>
                                            </NJVCard>
                                        </Col>
                                    </Row>
                                    <HowItWork />
                                </NJVCard>
                            </Col>
                        </Row>
                    </NJVCard>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(PointPage)