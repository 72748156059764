import { QuestionCircleOutlined } from '@ant-design/icons';
import { compose } from "@reduxjs/toolkit";
import { Checkbox, Col, ConfigProvider, FloatButton, Form, Image, Row, Typography, message, notification } from 'antd';
import i18next from "i18next";
import cookies from "js-cookie";
import React from "react";
import { Link } from "react-router-dom";
import { Authenticate } from "../../auth/auth-provider";
import { NJVButton, NJVCard, NJVInput } from "../../components/core-component";
import { SvgFacebookFilled, SvgLockFilled, SvgMessengerFilled, SvgMobileFilled, SvgMyanmarFlag, SvgPhoneOutlined, SvgUKFlag, SvgViberFilled } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import { LOC_storeToken } from "../../core/custom-local-storage";
import '../../css/login.css';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from "../../network/with-router";
import { updateAccessToken } from "../../redux/auth-slice";
import { updateSettlementNoti } from '../../redux/profile-slice';
import CustomPath from "../../routes/custom-path";
import { LoginPhoneNumberValidator } from '../../core/validator';
const { Title } = Typography;
const logo = require('../../asset/Ninja-Van-Logo-black-white.png');
const playStore = require('../../asset/playstore.png');
const appleStore = require('../../asset/appstore.png');
const landingImage = require('../../asset/landing-page-image.png')
const directDownload = require('../../asset/direct-download.png')

// const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.ninjavan.njvmms&pcampaignid=web_share'
// const appStoreUrl = 'https://apps.apple.com/sg/app/ninja-van-myanmar/id6474530077'
const directUrl = 'https://ninjavanstorages-1.s3.amazonaws.com/NVM+Shipper+Application/NinjaVanMyanmar.apk'

const playStoreUrl = 'http://bit.ly/3TUWSYD'
const appStoreUrl = 'http://apple.co/3TzdHqs'
// const directUrl = 'http://bit.ly/4bKeZGI'

const facebookUrl = 'https://www.facebook.com/NinjaVanMyanmar'
const messengerUrl = 'https://m.me/144308312834873?ref=Ninja'
const viberUrl = 'viber://pa?chatURI=ninjavanmyanmar&context=Ninja'

class LoginPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: '',
            visiblePassword: false,
            loggingIn: false,
            currentLanguage: 'en',
            isOwed: false
        }
        this.formRef = React.createRef()
    }

    componentDidMount = () => {
        const currentLanCode = cookies.get("i18next") || "en"
        this.setState({
            currentLanguage: currentLanCode
        })
        document.body.style.zoom = "100%";
    }

    login = async (values) => {
        message.destroy()
        if ((values.phoneNumber === undefined || values.phoneNumber === '') && (values.password === undefined || values.password === '')) {
            message.error("Please Enter Phone number & Password")
        } else if (values.phoneNumber === undefined || values.phoneNumber === '') {
            message.error("Please Enter Phone number")
        } else {
            const numberPattern = /^(09|01)\d{9}|\d{7}$/;
            if (!numberPattern.test(values.phoneNumber)) {
                message.error('Phone number must start with "09" or "01"');
            }else if (values.password === undefined || values.password === '') {
                message.error("Please Enter Password")
            } else{
                this.setState({
                    loggingIn: true
                })
                try {
                    const response = await Authenticate(values.phoneNumber, values.password, this.props)
                    if (response && response.status === 200) {
                        LOC_storeToken(response.data)
                        this.props.dispatch(updateAccessToken(response.data))
                        this.props.navigate(CustomPath.dashboard)
                        try {
                            const owedResponse = await ApiHandler({ url: Api.shipper_settlement_info, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
                            if (owedResponse && owedResponse.status === 'PENDING') {
                                const key = 'settlementInfo';
                                notification.info({
                                    message: 'Settlement Info',
                                    description: <p style={{ color: Theme.colors.primary, display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'end' }}>{owedResponse.content} <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary, width: 100 }} onClick={() => {
                                        this.props.navigate(CustomPath.invoice); notification.destroy(key);
                                        this.props.dispatch(updateSettlementNoti(true))
                                    }}>Pay Now</NJVButton> </p>,
                                    duration: 0,
                                    placement: 'top',
                                    key: key
                                })
                            }
                        } catch (error) {
                        }
                    } else if (response && response.response.status === 401) {
                        message.error("Phone Number or Password wrong!")
                        this.loginFail()
                    } else if (response && response.response.status === 500) {
                        message.error(response.response.data.message)
                        this.loginFail()
                    }
    
                } catch (error) {
                    message.error("System Error!")
                    this.loginFail()
                }
            }

        }


        // else if (values.phoneNumber.length > 12 || values.phoneNumber.length < 2) {
        //     message.error("Invalid Phone Number")
        // }

    }

    loginFail = () => {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({ 'password': '' })
        }

        this.setState({
            loggingIn: false
        })
    }

    handleInputChange = (fieldName, e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, '');
        this.formRef.current.setFieldsValue({ [fieldName]: numericValue })
    }

    showPassword = value => {
        this.setState({ visiblePassword: value.target.checked })
    }

    changeLanguage = () => {
        const { currentLanguage } = this.state
        if (currentLanguage === 'en') {
            i18next.changeLanguage("mm")
            this.setState({
                currentLanguage: 'mm'
            })
        } else if (currentLanguage === 'mm') {
            i18next.changeLanguage("en")
            this.setState({
                currentLanguage: 'en'
            })
        }
    }

    render() {
        const { visiblePassword, loggingIn, currentLanguage } = this.state
        const currentYear = new Date().getFullYear();

        return (
            <>

                <div
                    style={{
                        cursor: 'pointer',
                        position: 'fixed',
                        right: 15,
                        top: 15,
                        zIndex: 1,
                    }}>
                    <div style={{ display: 'flex', gap: 10 }} >
                        <NJVButton style={{ backgroundColor: Theme.colors.primary, fontWeight: 600, borderRadius: 50 }} type="primary" onClick={() => this.props.navigate(CustomPath.signUp)}>{i18next.t("register")}</NJVButton>
                        <div onClick={() => this.changeLanguage()}>
                            {
                                currentLanguage === 'en' ?
                                    <SvgUKFlag width={35} height={35} />
                                    :
                                    <SvgMyanmarFlag width={35} height={35} />
                            }
                        </div>
                    </div>
                </div >
                <Row style={{ height: '90vh', alignItems: 'center' }}>
                    <Col lg={12} md={10} sm={0} xs={0} style={{ backgroundColor: `${Theme.colors.primary}`, textAlign: 'center', height: '90vh', position: 'relative' }}>
                        <div style={{ position: 'absolute', left: 20 }}>
                            <Image
                                width={200}
                                src={logo}
                                preview={false}
                            />
                        </div>
                        <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                            <Image
                                src={landingImage}
                                preview={false}
                            />
                        </div>
                    </Col>
                    <Col lg={12} md={14} sm={24} xs={24}>
                        <Row style={{ alignContent: 'center' }}>
                            <Col lg={5} sm={1} xs={1} />
                            <Col lg={15} sm={22} xs={22}>
                                <NJVCard cardPadding={0} cardBgColor='#fff'>
                                    <Row>
                                        <Col span={24} style={{ marginBottom: 20 }}>
                                            <Title level={4}>{i18next.t("login_page_title")}</Title>
                                            <p>{i18next.t("login_page_text")}</p>
                                        </Col>
                                    </Row>
                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        onFinish={this.login}
                                        ref={this.formRef}
                                    >
                                        <Form.Item
                                            style={{ marginBottom: 30 }}
                                            name="phoneNumber"
                                        >
                                            <NJVInput
                                                className="login-box"
                                                style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                                prefix={<SvgMobileFilled width={20} height={20} color={"#898989"} />}

                                                placeholder={i18next.t("phone_number")}
                                                onChange={(e) => this.handleInputChange("phoneNumber", e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginBottom: 10 }}
                                            name="password"
                                        >
                                            <NJVInput
                                                className="login-box"
                                                style={{ background: '#f5f5f5', border: 'none', borderRadius: 12 }}
                                                prefix={<SvgLockFilled width={20} height={20} color={"#898989"} />}
                                                type={visiblePassword ? "text" : "password"}
                                                placeholder={i18next.t("password")}
                                                onChange={(e) => this.handleInputChange("password", e)}
                                            />
                                        </Form.Item>
                                        <Row>
                                            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Checkbox
                                                    style={{ marginBottom: 30 }}
                                                    onChange={this.showPassword}>
                                                    {i18next.t("show_password")}
                                                </Checkbox>
                                                <Form.Item>
                                                    <Link to={CustomPath.forgot_password}>
                                                        {i18next.t("forgot_password")}
                                                    </Link>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="login-form-button" >
                                                <Form.Item >
                                                    <NJVButton style={{ backgroundColor: Theme.colors.primary, fontWeight: 600 }} shape="none" type="primary" size="large" htmlType="submit" loading={loggingIn}>
                                                        {i18next.t("login")}
                                                    </NJVButton>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </NJVCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ position: 'fixed', height: '10vh', bottom: 0, left: 0, right: 0, background: '#000', alignItems: 'center', justifyContent: 'center' }}>
                    <Col lg={12} md={11} sm={24} xs={24}>
                        {/* Small Screen */}
                        <Col lg={0} md={0} xs={24} sm={24}>
                            <div style={{ display: 'flex', gap: 40, justifyContent: 'center', alignItems: 'center', paddingInline: 10, paddingTop: 3 }}>
                                <Image
                                    onClick={() => window.open(appStoreUrl)}
                                    width={110}
                                    src={appleStore}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Image
                                    onClick={() => window.open(playStoreUrl)}
                                    width={110}
                                    src={playStore}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Image
                                    onClick={() => window.open(directUrl)}
                                    width={110}
                                    src={directDownload}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Col>

                        {/* Large Screen */}
                        <Col lg={24} md={24} xs={0} sm={0}>
                            <div style={{ display: 'flex', gap: 40, justifyContent: 'center', alignItems: 'center' }}>
                                <Image
                                    onClick={() => window.open(appStoreUrl)}
                                    width={123}
                                    src={appleStore}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Image
                                    onClick={() => window.open(playStoreUrl)}
                                    width={123}
                                    src={playStore}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Image
                                    onClick={() => window.open(directUrl)}
                                    width={123}
                                    src={directDownload}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </Col>
                    </Col>
                    <Col lg={12} md={0} sm={0} xs={0} style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: 15, color: '#fff' }}>{`© Copyright Ninja Van Myanmar ${currentYear}. All rights reserved.`}</span>
                    </Col>
                    <Col lg={0} md={11} sm={24} xs={24} style={{ textAlign: 'center' }}>
                        <span style={{ color: '#fff' }}>{`© Copyright Ninja Van Myanmar ${currentYear}. All rights reserved.`}</span>
                    </Col>
                </Row>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: Theme.colors.primary,
                            controlHeightLG: 43,
                            fontSizeIcon: 13
                        },
                    }}
                >
                    <FloatButton.Group
                        trigger="click"
                        type="primary"
                        style={{
                            right: 24,
                            bottom: 100,
                        }}
                        icon={<QuestionCircleOutlined />}
                        size="large"
                    >
                        <FloatButton onClick={() => window.location.href = 'tel:+959777111001'} icon={<SvgPhoneOutlined width="17" height="17" color="#1677ff" style={{ cursor: 'pointer' }} />} />
                        <FloatButton onClick={() => window.open(facebookUrl)} icon={<SvgFacebookFilled width="17" height="17" color="#1677ff" style={{ cursor: 'pointer' }} />} />
                        <FloatButton onClick={() => window.open(messengerUrl)} icon={<SvgMessengerFilled width="17" height="17" color="#1677ff" style={{ cursor: 'pointer' }} />} />
                        <FloatButton onClick={() => window.open(viberUrl)} icon={<SvgViberFilled width="20" height="20" color="#1677ff" style={{ cursor: 'pointer' }} />} />
                    </FloatButton.Group>
                </ConfigProvider>
            </>
        )
    }
}

export default compose(withRouter)(LoginPage)