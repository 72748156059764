import { compose } from "@reduxjs/toolkit";
import { Col, ConfigProvider, Image, List, Row, Segmented, Skeleton } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import React from "react";
import { NJVButton, NJVCard } from "../../components/core-component";
import { SvgInvoiceFilled, SvgNotiActivity, SvgNotiAnnocement, SvgNotiSystem, SvgOrderDollyOutlined } from "../../components/custom-svgIcon";
import { TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import { Constant } from "../../core/constant";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
const empty_history_image = require('../../asset/shipper_history_empty.png')



class NotificationPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDataFetching: false,
            data: [],
            page: 0,
            pageSize: Constant.pageSize,
            isLastData: false
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (requestParams) => {
        this.setState({
            isDataFetching: true
        })
        try {

            const response = await ApiHandler({ url: Api.notification_history_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })

            if (response) {
                let updatedList = []
                response.content && response.content.forEach(noti => {

                    const notiTime = dayjs(noti.createdDate, 'YYYY-MM-DD HH:mm:ss');
                    if (notiTime) {
                        noti = { ...noti, createdDate: notiTime.format('DD MMM,YYYY hh:mm A') }
                    }
                    updatedList.push(noti)

                })

                this.setState({
                    data: updatedList
                })
            }
        } catch (error) {
        }
        this.setState({ isDataFetching: false })
    }

    loadMore = async () => {
        const { data, pageSize, page, notificationType } = this.state
        this.setState({
            isLoadMore: true
        })
        const requestParams = {
            size: pageSize,
            page: page + 1,
            notificationType
        }

        try {
            const response = await ApiHandler({ url: Api.notification_history_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let updatedList = data
                // let isLastData = false
                if (response.content) {
                    response.content.forEach(noti => {

                        const notiTime = dayjs(noti.createdDate, 'YYYY-MM-DD HH:mm:ss');
                        if (notiTime) {
                            noti = { ...noti, createdDate: notiTime.format('DD MMM, YYYY hh:mm A') }
                        }
                        updatedList.push(noti)
                    })
                    // if (response.content.length < 20) {
                    //     isLastData = true
                    // }
                }
                this.setState({
                    data: updatedList,
                    isLastData: response.last,
                    page: page + 1
                })
            }
        } catch (error) {

        }
        this.setState({
            isLoadMore: false
        })
    }

    routToNotificationDetail = (id, trackingNumber, notificationType) => {
        if (notificationType === 'ORDER_ACTIVITY') {
            const data = {
                id: trackingNumber,
                isFromOrderHistory: false
            }
            this.props.navigate(
                CustomPath.order_detail,
                { state: { data: data } }
            );
        } else {
            this.props.navigate(
                CustomPath.notification_detail,
                { state: { data: id } }
            );
        }

    }

    handleSegment = (value) => {
        if (value === 'ALL') {
            this.fetchData()
            this.setState({
                notificationType: '',
            })
        } else {
            const requestParams = {
                notificationType: value
            }
            this.setState({
                notificationType: value,
            })
            this.fetchData(requestParams)
        }
        this.setState({
            page: 0,
            isLastData: false
        })
    }

    formatCreatedDate = (item) => {
        const createdDate = dayjs(item);
        
        const now = dayjs();

        const diffDays = now.diff(createdDate, 'day');
        const diffHours = now.diff(createdDate, 'hour');
        const diffMinutes = now.diff(createdDate, 'minute');

        if (diffMinutes < 1) {
            return 'Just now';
        }else if(diffMinutes < 60) {
            return `${diffMinutes} minutes ago`;
        }else if(diffHours < 24){
            return `${diffHours} hours ago`
        }else if(diffDays === 1){
            return 'yesterday';
        }else if (diffDays <= 6) {
            return `${createdDate.format('dddd')}`;
        } else {
            return createdDate.format('YYYY-MM-DD');
        }
    }

    render() {
        const { data, isDataFetching, isLoadMore, isLastData } = this.state
        return (
            <>
                <Row>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <TitleLevel3 label={i18next.t("notification")} />
                            </Col>
                            <Col span={24}>
                                <ConfigProvider theme={{
                                    components: {
                                        Segmented: {
                                            itemSelectedBg: Theme.colors.primary,
                                            itemSelectedColor: Theme.colors.white
                                        }
                                    }
                                }}>
                                    <Segmented style={{ marginBottom: 20, marginTop: 10 }} size='large' options={[
                                        {
                                            label: <span style={{ fontWeight: '600', fontSize: 14, }}>{i18next.t('all')}</span>,
                                            value: 'ALL'
                                        },
                                        {
                                            label: <span style={{ fontWeight: '600', fontSize: 14, }}>{i18next.t('order_information')}</span>,
                                            value: 'ORDER_ACTIVITY'
                                        },
                                        {
                                            label: <span style={{ fontWeight: '600', fontSize: 14, }}>{i18next.t('announcement')}</span>,
                                            value: 'ANNOUNCEMENT'
                                        }
                                    ]}
                                        onChange={this.handleSegment}
                                        block />
                                </ConfigProvider>
                            </Col>
                            <Col span={24}>
                                {
                                    isDataFetching ?
                                        <Skeleton active />
                                        :
                                        <>
                                            {
                                                data ?
                                                    <>
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={data}
                                                            renderItem={(item, index) => (
                                                                <NJVCard
                                                                    className="card-shadow"
                                                                    
                                                                    style={{
                                                                        paddingBottom: 10,
                                                                        paddingTop: 10,
                                                                        marginBottom: 10,
                                                                        borderRadius: 20,
                                                                        width: '100%',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    cardBgColor='white'
                                                                    onClick={() => this.routToNotificationDetail(item.id, item.trackingNumber, item.notificationType)}
                                                                >
                                                                    <Row style={{ width: '100%' }}>
                                                                        <Col span={24}>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div
                                                                                    style={{
                                                                                        width: 45,
                                                                                        height: 42,
                                                                                        borderRadius: 30,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        backgroundColor: Colors.primary,
                                                                                        marginLeft: 10,
                                                                                        marginRight: 20
                                                                                    }}>
                                                                                    {
                                                                                        item.notificationType === 'ACTIVITY' ?
                                                                                            <SvgNotiActivity width={20} height={20} color={'#fff'} />
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    item.notificationType === 'SYSTEM' ?
                                                                                                        <SvgNotiSystem width={20} height={20} color={'#fff'} />
                                                                                                        :
                                                                                                        <>
                                                                                                            {
                                                                                                                item.notificationType === 'ANNOUNCEMENT' ?
                                                                                                                    <SvgNotiAnnocement width={20} height={20} color={'#fff'} />
                                                                                                                    :

                                                                                                                    item.notificationType === 'ORDER_ACTIVITY' ?
                                                                                                                        <SvgOrderDollyOutlined width={20} height={20} color={'#fff'} />
                                                                                                                        :
                                                                                                                        item.notificationType === 'SETTLEMENT' ? <SvgInvoiceFilled width={20} height={20} color={'#fff'} /> :
                                                                                                                            <></>
                                                                                                            }
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                    }

                                                                                </div>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 17,
                                                                                                fontWeight: '600'
                                                                                            }}
                                                                                        > {item.title}</span>
                                                                                        <span>
                                                                                            {this.formatCreatedDate(item.createdDate)}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div>{item.content}</div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </NJVCard>

                                                            )}
                                                        />
                                                        {
                                                            isLastData ?
                                                                <></>
                                                                :
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <NJVButton type="primary" onClick={() => this.loadMore()} loading={isLoadMore}>
                                                                        Load More
                                                                    </NJVButton>
                                                                </div>

                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Image
                                                                width={200}
                                                                preview={false}
                                                                src={empty_history_image} />
                                                        </Col>
                                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                            <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                                {i18next.t("no_notification_yet")}
                                                            </div>
                                                            <div style={{ fontSize: 16 }}>
                                                                {i18next.t("msg_notification")}
                                                            </div>
                                                        </Col>
                                                    </>

                                            }
                                        </>

                                }
                            </Col>
                        </Row>

                    </Col >
                </Row >

            </>
        )
    }
}

export default compose(withRouter)(NotificationPage)